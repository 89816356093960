import config from "../config/config";
import { showSnackbar } from '../components/notification/index';

/**
 * Returns the token key
 * @returns string || ''
 */
const getTokenKey = () => {
    const { tokenKey } = config;
    return tokenKey;
}

/**
 * returns true if logged in
 * @returns Bool {is logged in}
 */
export const isLoggedIn = () => window.localStorage.getItem(getTokenKey()) !== null;

/**
 * returns the token if logged in
 * @returns {string} the token
 */
export const getAuthToken = () => window.localStorage.getItem(getTokenKey());

/**
 * sets the auth Token
 * @param {string} token
 * @returns {boolean} success
 */
export const setAuthToken = (token) => window.localStorage.setItem(getTokenKey(), token);

/**
 * logs out the user silently
 * @param {string} token
 * @returns {boolean} success
 */
export const logoutUserSilent = () => window.localStorage.removeItem(getTokenKey());

/**
 * logs the user out
 * @param {string} alertMessage Optional alert message to show
 * @returns void
 */
export const logoutUser = (alertMessage = '') => {
    if (alertMessage) {
        showSnackbar({ timeout: 1000 }, () => {
            window.localStorage.removeItem(getTokenKey());
            window.location.href = "/auth/login"
        }).info(alertMessage);
    } else {
        // remove local storage and redirect
        window.localStorage.removeItem(getTokenKey());
        window.location.href = "/auth/login"
    }

};


/**
 * Get the logged in user details
 * @returns Object
 */
export const getAuthId = () => {
    try {
        const authToken = getAuthToken();
        let details = {};
        if (authToken) {
            details = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        return details.sub || ''
    } catch (e) {
        return '';
    }
};

/**
 * Get the logged in user role name
 * @returns {string}
 */
export const getAuthRoleName = () => {
    try {
        const authToken = getAuthToken();
        let extractedToken = {};
        if (authToken) {
            extractedToken = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        const role = extractedToken?.details?.roleId?.roleName
        return role || ''
    } catch (e) {
        return '';
    }
};

/**
 * Get the logged in user role name
 * @param {string} key the key of the value needed. Eg: to get email pass email
 * @returns {any} || boolean Incase key is not found, will return empty string
 */
export const getAuthDetailsByKey = (key) => {
    let result = '';
    try {
        const authToken = getAuthToken();
        let extractedToken = {};        
        if (authToken) {
            extractedToken = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        if (key && extractedToken?.details[key]) {
            result = extractedToken?.details[key];
        }
        return result;
    } catch (e) {
        return result;
    }
};
