import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '@mui/material';
import AppLayout from '../../layouts/app/AppLayout';
import AppIcons from '../../assets/images/icons/index';
import SVGIcons from '../../assets/images/icons/svgIcons';
import './home.scss';
import './bannerAnimation.scss';
import { getAuthRoleName } from '../../helpers/auth.helper';
// import bannerVideo from '../../assets/images/bannerVideo.mp4';
// import animatedBannerMainBG from '../../assets/images/animatedBanner/mainBG.png';
import animatedBannerBall from '../../assets/images/animatedBanner/ball.png';
import animatedBannerBallon1 from '../../assets/images/animatedBanner/ballon1.png';
import animatedBannerBallon2 from '../../assets/images/animatedBanner/ballon2.png';
import animatedBannerBallon3 from '../../assets/images/animatedBanner/ballon3.png';
import animatedBannerBlock1 from '../../assets/images/animatedBanner/block1.png';
import animatedBannerBlock2 from '../../assets/images/animatedBanner/block2.png';
import animatedBannerChild from '../../assets/images/animatedBanner/child.png';
import animatedBannerChildDino from '../../assets/images/animatedBanner/childDino.png';
import animatedBannerCloud1 from '../../assets/images/animatedBanner/cloud1.png';
import animatedBannerCloud2 from '../../assets/images/animatedBanner/cloud2.png';
import animatedBannerCloud3 from '../../assets/images/animatedBanner/cloud3.png';
import animatedBannerCloud4 from '../../assets/images/animatedBanner/cloud4.png';
import animatedBannerDuck from '../../assets/images/animatedBanner/duck.png';
import animatedBannerKite from '../../assets/images/animatedBanner/kite.png';
import animatedBannerLogoK from '../../assets/images/animatedBanner/LogoK.png';
import animatedBannerPony from '../../assets/images/animatedBanner/pony.png';
import animatedBannerRocket from '../../assets/images/animatedBanner/rocket.png';
import animatedBannerWorld from '../../assets/images/animatedBanner/World.png';
import animatedBannerFooter from '../../assets/images/animatedBanner/bannerFooter.png';

const aboutItems = [
    {
        className: 'box1',
        icon: AppIcons.Playground,
        title: 'Playground',
        discription: 'We are play-based, fueling imagination and adventure, as children play and learn.',
    },
    {
        className: 'box2',
        icon: AppIcons.apple,
        title: 'Food program',
        discription: 'We nourish young minds with nutritious foods for a healthy growth.',
    },
    {
        className: 'box3',
        icon: AppIcons.brain,
        title: 'Positive learning',
        discription: 'We help children learn, give them love and make sure they are always happy.',
    },
    {
        className: 'box4',
        icon: AppIcons.car,
        title: 'Transportation',
        discription: 'We can accommodate transportation to ensure your peace of mind.',
    },
    {
        className: 'box5',
        icon: AppIcons.child,
        title: 'Ages',
        discription: <>We accept care for kids and private care for children  aged <b>6 weeks to 5 years old</b>.</>,
    },
    {
        className: 'box6',
        icon: AppIcons.events,
        title: 'Events',
        discription: 'We often host fun events: science experiments, movie nights, holidays, birthdays.',
    },
    {
        className: 'box7',
        icon: AppIcons.language,
        title: 'Bilingual',
        discription: <>We provide reading and witing techniques in English and Spanish.</>,
    },

];

const pressDetails = [
    {
        pressName: <><img src={AppIcons.V} alt='press' /> Vox</>,
        link: 'https://www.vox.com/the-highlight/22977657/future-of-work-child-care-worker-shortage',
        newsTitle: 'When your job helps the rest of America work',
        contend: 'Why so many are giving up on child care work and what it will mean for everyone else.',
        date: 'Apr 11th, 2022',
        pressImg: AppIcons.press1,
    },
    {
        pressName: <><img src={AppIcons.ct} alt='press' /> Ct Mirror</>,
        link: 'https://ctmirror.org/author/katherine-lantigua/',
        newsTitle: 'Opinion: CT is in a child care crisis. Here’s what will fix it',
        contend: 'CT has the opportunity to expand a state program that would uplift parents, children and child care providers.',
        date: 'Feb 19th, 2023',
        pressImg: AppIcons.press2,
    },
];



const HomePage = () => {

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('letAnimate');
        }, 500);
    });

    // custom home functions here
    const pageName = "Home";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='home-main-wrp f-wrp'
        >

            <div className='animated-banner-wrp f-wrp'>
                <div className='bannerBG-con main-banner-wrp f-wrp'>
                    <div className='baner-content-sec f-wrp'>
                        <div className='container'>
                            <div className='banner-content f-wrp'>
                                <span>
                                    <div className='animntlogo'>
                                        <img className='animatedK' src={animatedBannerLogoK} alt='banner' />
                                    </div>
                                    <img src={AppIcons.bannerLogo} alt='logo' />
                                </span>
                                {(getAuthRoleName()) && <Button onClick={() => {
                                    window.location.href = '/my-portal'
                                }} className='banner-btn'>{getAuthRoleName()}’s portal
                                </Button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-img-wrp f-wrp'>
                    {/* <span className='bannerBG'><img src={animatedBannerMainBG} alt='banner' /></span> */}
                    <span className='bannerBGmob'><img src={AppIcons.kcolorfulBanner} alt='banner' /></span>
                    <div className='banner-main f-wrp'>
                        <div className='bannerBG'>
                            <img src={animatedBannerChild} alt='banner' />
                            <div className='flying-grup'>
                                <span className='flying rocket'><img src={animatedBannerRocket} alt='banner' /></span>
                                <span className='flying kite'><img src={animatedBannerKite} alt='banner' /></span>
                            </div>
                        </div>
                        <span className='cloud cloud1'><img src={animatedBannerCloud1} alt='banner' /></span>
                        <span className='cloud cloud2'><img src={animatedBannerCloud2} alt='banner' /></span>
                        <span className='cloud cloud3'><img src={animatedBannerCloud3} alt='banner' /></span>
                        <span className='cloud cloud4'><img src={animatedBannerCloud4} alt='banner' /></span>
                        <div className='child-grup-sec'>
                            <span className='child-grup ChildDino'><img src={animatedBannerChildDino} alt='banner' /></span>
                            <span className='child-grup Pony'><img src={animatedBannerPony} alt='banner' /></span>
                            <span className='child-grup Ball'><img src={animatedBannerBall} alt='banner' /></span>
                            <span className='child-grup Block1'><img src={animatedBannerBlock1} alt='banner' /></span>
                            <span className='child-grup Block2'><img src={animatedBannerBlock2} alt='banner' /></span>
                            <span className='child-grup duck'><img src={animatedBannerDuck} alt='banner' /></span>
                            <div className='balloon-grup f-wrp'>
                                <span className='child-grup Ballon1'><img src={animatedBannerBallon1} alt='banner' /></span>
                                <span className='child-grup Ballon2'><img src={animatedBannerBallon2} alt='banner' /></span>
                                <span className='child-grup Ballon3'><img src={animatedBannerBallon3} alt='banner' /></span>
                            </div>
                        </div>

                        <div className='banner-basic f-wrp'>
                            <span className='world'><img src={animatedBannerWorld} alt='banner' /></span>
                            <span className='bannerFooter'><img src={animatedBannerFooter} alt='banner' /></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='main-banner-wrp f-wrp'>
                <span className='bannerBG'><img src={AppIcons.kcolorfulBanner} alt='banner' /></span>
                <video autoPlay muted id="myVideo" style={{width: '100%'}}>
                    <source src={bannerVideo} type="video/mp4" />
                        Your browser does not support HTML5 video.
                </video>
                <div className='bannerBG-con f-wrp'>
                    <div className='baner-content-sec f-wrp'>
                        <div className='container'>
                            <div className='banner-content f-wrp'>
                                <span>
                                    <img src={AppIcons.logo} alt='logo' />
                                    <img src={AppIcons.logo} alt='logo' />
                                </span>
                                {(getAuthRoleName()) && <Button onClick={() => {
                                    window.location.href = '/my-portal'
                                }} className='banner-btn'>{getAuthRoleName()}’s portal
                                </Button>}
                            </div>
                        </div>
                    </div>
                </div>
                <span className='childern'><img src={AppIcons.childern} alt='Childern' /></span>
                <span className='clouds'><img src={AppIcons.clouds} alt='Clouds' /></span>
                <span className='dinoGroup'><img src={AppIcons.dinoGroup} alt='Dino Group' /></span>
            </div> */}

            <div className='each-sec-wrapper abt-con-wrp f-wrp'>
                <div className='line-sec'>
                    <div className='abt-sec-line_1'><img src={AppIcons.line} alt='line' /></div>
                    <div className='abt-sec-line_2'><img src={AppIcons.line} alt='line' /></div>
                </div>
                <div className='container'>
                    <span className='abt-img-sec'>
                        <img src={AppIcons.abtChild} alt='child' />
                    </span>
                    <div className='abt-header f-wrp'>
                        <h2>About us</h2>
                        <p>My name is <b>Miss K.</b> and I have been providing licensed child care in Bridgeport for 6+ years. We accept care for kids and private care for children aged 6 weeks to 5 years old.</p>
                    </div>
                    <div className='content-sec-wrp f-wrp'>
                        <div className="row">
                            {aboutItems.map((element, key) => (
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12" key={key}>
                                    <div className={`${element.className} each-abt-sec f-wrp `}>
                                        <span className='abt-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        <p>{element.discription}</p>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            slidesPerView={1.01}
                            className='content-sec-mob'
                            navigation
                        >
                            {aboutItems.map((element, key) => (
                                <SwiperSlide key={key}>
                                    <div className={`${element.className} each-abt-sec f-wrp `}>
                                        <span className='abt-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        <p>{element.discription}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper team-con-wrp f-wrp'>
                <div className='team-img-sec f-wrp'>
                    <span><img src={AppIcons.teamBG} alt='team' /></span>
                </div>
                <div className='team-con-sec f-wrp'>
                    <div className='container'>
                        <span><h2>We get parents involved because we are a team {SVGIcons.HeartIcon()}</h2></span>
                    </div>
                </div>
                <div className='team-lft-objt f-wrp'>
                    {/* <div className='each-objt objtBG'>
                        <span><img src={AppIcons.union} alt='bg' /></span>
                    </div> */}
                    <div className='each-objt parentBG'>
                        <span><img src={AppIcons.Illu} alt='parents' /></span>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper learn-more-wrp f-wrp'>
                <div className='containe r'>
                    <div className='learn-more-sec f-wrp'>
                        <div className='learn-con-wrp f-wrp'>
                            <span className='bg-tmp'><img src={AppIcons.lernmore_bgTP} alt='bg' /></span>
                            <div className='content-wrp f-wrp'>
                                <h1>Learn about our Program</h1>
                                <p>We create an enriching learning experience that encompasses creativity, movement, exploration and knowledge in a holistic approach.</p>
                                <ul>
                                    <li><span>{SVGIcons.BrushIcon()}</span><p>Arts and crafts</p></li>
                                    <li><span>{SVGIcons.BookOpenIcon()}</span><p>Reading techniques</p></li>
                                    <li><span>{SVGIcons.SignatureIcon()}</span><p>Writing techniques</p></li>
                                    <li><span>{SVGIcons.MathSymbolsIcon()}</span><p>Math skills</p></li>
                                    <li><span>{SVGIcons.DancingIcon()}</span><p>Dancing</p></li>
                                    <li><span>{SVGIcons.MusicIcon()}</span><p>Music play</p></li>
                                    <li><span>{SVGIcons.TravelIcon()}</span><p>Trips</p></li>
                                    <li><span>{SVGIcons.TreeIcon()}</span><p>Outdoor activities</p></li>
                                    <li><span>{SVGIcons.BrandIcon()}</span><p>Circle time</p></li>
                                    <li><span>{SVGIcons.SpellBookIcon()}</span><p>Story time</p></li>
                                    <li><span>{SVGIcons.YogaIcon()}</span><p>Yoga classes</p></li>
                                </ul>
                                <div className='learn-con-slider'>
                                    <Swiper
                                        // modules={[Navigation]}
                                        spaceBetween={10}
                                        // slidesPerView={5}
                                        // slidesPerView={'auto'}
                                        slidesPerView={'auto'}
                                        slidesPerColumnFill="row"
                                        autoplay
                                        className='each-learn-con-slider'
                                    // navigation
                                    >
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.BrushIcon()}</span><p>Arts and crafts</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.BookOpenIcon()}</span><p>Reading techniques</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.SignatureIcon()}</span><p>Writing techniques</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.MathSymbolsIcon()}</span><p>Math skills</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.DancingIcon()}</span><p>Dancing</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.MusicIcon()}</span><p>Music play</p></li>
                                            </ul>
                                        </SwiperSlide>

                                    </Swiper>
                                    <Swiper
                                        // modules={[Navigation]}
                                        spaceBetween={10}
                                        slidesPerView="auto"
                                        className='each-learn-con-slider'
                                    // navigation
                                    >
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.TravelIcon()}</span><p>Trips</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.TreeIcon()}</span><p>Outdoor activities</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.BrandIcon()}</span><p>Circle time</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.SpellBookIcon()}</span><p>Story time</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.YogaIcon()}</span><p>Yoga classes</p></li>
                                            </ul>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <span className='bg-tmp'><img src={AppIcons.lernmore_bgBtm} alt='bg' /></span>
                        </div>
                        <div className='f-wrp'>
                            <div className='learn-img-wrp'>
                                <span className='main-img'><img src={AppIcons.Child_1} alt='child' />
                                    <span className='horse-img'><img src={AppIcons.horseGrup} alt='child' /></span>
                                </span>
                                <span className='sub-img'><img src={AppIcons.Child_2} alt='child' /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper staff-sec-wrp f-wrp'>
                <div className='clouds-float-wrp f-wrp'>
                    <span className='clouds-img'><img src={AppIcons.cloud1} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloud2} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloud3} alt='cloud' /></span>
                </div>
                <div className='container'>
                    <div className='staff-sec-container f-wrp'>
                        <div className='img-sec-wrp'>
                            <span className='staff-img'><img src={AppIcons.staff} alt='staff' /></span>
                        </div>
                        <div className='con-sec-wrp'>
                            <div className='staff-con-img f-wrp'>
                                <span className='angel-img'><img src={AppIcons.angel1} alt='angel' /></span>
                                <span className='baloon-img'><img src={AppIcons.balloon} alt='baloon' /></span>
                                <span className='angel2-img'><img src={AppIcons.angel2} alt='angel' /></span>
                            </div>
                            <div className='staff-con f-wrp'>
                                <h1>Meet Katherine</h1>
                                <p>The exceptional founder of Kcolorful Daycare, who is passionate about children’s development and well being.  <b>Katherine Lantigua,</b> is a certified  childcare provider with a wealth of experience in nurturing and educating children. </p>
                                <p>Katherine's qualifications go beyond child care, as she is also a trained medical assistant. In addition, she holds certifications in first aid and CPR, equipping her with the skills to handle any unexpected situations with confidence and efficiency. Katherine, also represents more than 4,000+ childcare facilities in Connecticut.</p>
                                <Link className='pln-btn' to={'/'}>Read more {SVGIcons.ArrowTrIcon()}</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <span className='lady-img'><img src={AppIcons.Lady} alt='Lady' /></span>
            </div>

            <div className='each-sec-wrapper press-sec-wrp f-wrp'>
                <div className='container'>
                    <div className='press-header f-wrp'>
                        <span className='press-icon'><img src={AppIcons.pressIcon} alt='press' /></span>
                        <h1>Katherine on the press</h1>
                        <p>Check out these interviews showcasing our Kathy’s work and contribution to the childcare community.</p>
                    </div>
                    <div className='press-content f-wrp'>
                        <div className='row'>

                            {pressDetails.map((element, key) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" key={key}>
                                    <Link onClick={() => window.open(`${element.link}`, '_blank')} className='f-wrp'>
                                        <div className='basic-news-blk f-wrp'>
                                            <span className='press-basic'>{element.pressName}</span>
                                            <h3 className='press-title'>{element.newsTitle}</h3>
                                            <p>{element.contend}</p>
                                            <span className='date'>{element.date}</span>
                                        </div>
                                        <span className='press-img'><img src={element.pressImg} alt='press' /></span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className='press-content-slider f-wrp'>
                            <Swiper
                                // modules={[Navigation]}
                                spaceBetween={10}
                                // slidesPerView={5}
                                slidesPerView={'auto'}
                                className='each-learn-con-slider'
                            // navigation
                            >

                                {pressDetails.map((element, key) => (
                                    <SwiperSlide key={key}>
                                        <Link onClick={() => window.open(`${element.link}`, '_blank')} className='f-wrp'>
                                            <div className='basic-news-blk f-wrp'>
                                                <span className='press-basic'>{element.pressName}</span>
                                                <h3 className='press-title'>{element.newsTitle}</h3>
                                                <p>{element.contend}</p>
                                                <span className='date'>{element.date}</span>
                                            </div>
                                            <span className='press-img'><img src={element.pressImg} alt='press' /></span>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                        </div>
                    </div>

                    <div className='crp-block-wrp f-wrp'>
                        <div className='crp-header f-wrp'>
                            <span className='press-icon'><img src={AppIcons.headLft} alt='icons' /></span>
                            <h1>Connecticut Childcare CPR</h1>
                            <span className='press-icon'><img src={AppIcons.headRyt} alt='icons' /></span>
                        </div>
                        <div className='crp-con-blk f-wrp'>
                            <h1>Obtain or Renew your First Aid License <img src={AppIcons.txt} alt='icons' /> <span className='slide-txt'>Bilingual</span></h1>
                            <p>If you are a childcare provider and want to obtain or renew your First Aid License, you can take our classes. We have several courses available and we are located in Bridgeport, Connecticut.</p>
                            <div className='rate-box f-wrp'>
                                <h2>Required by the State of Connecticut</h2>

                                <div className='rate'>
                                    <h1>$125</h1>
                                    <h5>per person<span>non refundable</span></h5>

                                </div>
                            </div>
                            <div className='into-box'>
                                <h1>CERTIFICATE OF COMPLETION</h1>
                                <p>IS GRANTED AFTER COURSEWORK</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='each-sec-wrapper contct-sec-wrp f-wrp'>
                <div className='container'>
                    <div className='contact-sec-container f-wrp'>
                        <div className='contct-img-sec'>
                            <span className='press-icon'><img src={AppIcons.logoK} alt='logo' /></span>
                        </div>
                        <div className='contct-con-sec'>
                            <div className='flex-box'>
                                <div className='conct-main-con'>
                                    <h1>Find us, get in touch!</h1>
                                    <p>Discover a world of joy and growth for your child at Kcolorful where laughter and learning come together in perfect harmony.</p>
                                    <ul>
                                        <li><span>{SVGIcons.PointerIcon()}</span><p>408 Barnum Ave, Bridgeport, CT 06608</p></li>
                                        <li><span>{SVGIcons.EmailIcon()}</span><p>kcolorfuldaycare@gmail.com</p></li>
                                        <li><div><span>{SVGIcons.TeleIcon()}</span><p>Fax: 203-549-9862</p></div>
                                            <div><span>{SVGIcons.phoneIcon()}</span><p>Cell: 347-257-2417</p></div></li>
                                    </ul>
                                </div>
                                <div className='wall-painting'>
                                    <span className='wall-paint'><img src={AppIcons.walpaper} alt='walpaper' /></span>
                                </div>
                            </div>
                            <ul className='social-list'>
                                <li className='whtsap'><Link onClick={() => window.open('https://wa.me/3472572417', '_blank')}><span><img src={AppIcons.whtsap} alt='whatsapp' /></span><p>Whatsapp</p></Link></li>
                                <li className='fb'><Link><span><img src={AppIcons.fb} alt='fb' /></span><p>Facebook</p></Link></li>
                                <li className='insta'><Link onClick={() => window.open('https://www.instagram.com/kcolorfuldaycare/?hl=en', '_blank')}><span><img src={AppIcons.insta} alt='insta' /></span><p>Instagram</p></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper video-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <span className='video-sec-bg'><img src={AppIcons.videoBG} alt='video' /></span>
                    <div className='video-btn-blk'>
                        <span className='video-icon'><img src={AppIcons.videoIcon} alt='video icon' /></span>
                        <Link className='blk-btn' to={'/'}>Request video tour</Link>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper enroll-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <span className='castle'><img src={AppIcons.castle} alt='castle' /></span>
                    <div className='container'>
                        <div className='enroll-sec-continer f-wrp'>
                            <h1>Enrollment</h1>
                            <p>Thank you for your interest in our childcare. <br />We look forward to meeting you and your child! </p>
                            <p>A set of forms* are required for enrollment, you can complete them online at our Parent Portal. There, you will be able to access all your completed forms, update them, print them or delete them.</p>
                            <span>*The LIC form documents are required for all family daycares and can be found at Department of social services website: http://www.dss.cahwnet.gov/cdssweb/PG166.htm#lic</span>
                            <Link className='blk-btn' to={'/'}>Parent Portal</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper tuition-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <div className='tuition-header f-wrp'>
                        <h1>Tuition & Fees</h1>
                        <p>Spark your child's love for learning and exploration. <br />
                            We offer a world of educational adventures tailored to their interests and developmental needs.</p>
                        <div className='tution-head-img'>
                            <img className='tution-img1' src={AppIcons.readingKid} alt="tution header" />
                            <img className='tution-img2' src={AppIcons.rainbow} alt="tution header" />
                            <img className='tution-img3' src={AppIcons.kidbg} alt="tution header" />
                        </div>
                    </div>
                </div>

                <div className='clouds-float-wrp f-wrp'>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                </div>
                <div className='container'>
                    <div className='tuition-fee-structure f-wrp'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid1} alt="Infants & Toddlers" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">
                                        <h3>Infants & Toddlers</h3>
                                        <div className='trip-grid'>
                                            <div className='age'>
                                                <span><b>Age:</b>6 wks-5 years</span>
                                            </div>
                                            <div className='days'>
                                                <span><b>Days:</b>Mon to Fri</span>
                                            </div>
                                            <div className='hours'>
                                                <span><b>Hours:</b>6am - 7pm</span>
                                            </div>
                                        </div>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Hourly: </td>
                                                        <td><span className='price'>$8</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid2} alt="Transportation" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">
                                        <h3>Transportation</h3>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Weekly round trip: </td>
                                                        <td><span className='price'>$70</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Weekly single trip: </td>
                                                        <td><span className='price'>$45</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid3} alt="Infants & Toddlers" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">

                                        <h3>Infants & Toddlers</h3>
                                        <div className='trip-grid'>
                                            <div className='age'>
                                                <span><b>Duration:</b>Hold a spot for 2 weeks</span>
                                            </div>
                                            <div className='hours'>
                                                <span><b style={{ color: '#FFF4EE' }}>Non<br />Refundable</b></span>
                                            </div>
                                        </div>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Fee: </td>
                                                        <td><span className='price'>$200</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div className='tuition-info-txt'>
                                    <h2>We only offer full time daycare services. </h2>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div className='tuition-info-txt' style={{ float: 'right' }}>
                                    <h2>We only work part-time for evening childcare.</h2>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




            <div className='each-sec-wrapper payment-sec-wrp f-wrp'>
                <div className='container'>
                    <span className='payment-img-sec'><img src={AppIcons.paymentBGImg} alt='payment' /></span>
                    <div className='payment-sec-container'>
                        <div className='payment-sec-con f-wrp'>
                            <h1>Pay online</h1>
                            <p>It's important that our parents have easy and secure payment options. We offer contactless payment options at drop off, but for your convenience, you can also pay online!</p>
                            <p>Upon enrollment, there is a $100 Registration Fee per child. To be paid at the time the Day Care Contract is signed.
                                <span>The registration fee is nonrefundable and is not applied to any child care fees.</span></p>
                            <p>Pay Online here by entering the tuition fee that corresponds to your child, create a profile for each of your children and pay their tuition separately:</p>
                            <Link className='blk-btn' to={'/'}>Pay online</Link>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default HomePage;