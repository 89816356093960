import React from 'react';
import propTypes from 'prop-types';
import { Grid } from '@mui/material';
// Custom components
import AppIcons from '../../../../assets/images/icons';
// Components

// Helpers
import TemplateFormOne from './facilityTemplateForms/templateFormOne';
import TemplateFormTwo from './facilityTemplateForms/templateFormTwo';




const FacilitiesCreateWebsite = (props) => {

    const { data = {}, onDataChange = () => { }, language = 'en' } = props;
    const {
        // selectedTemplateData = {},
        website = {},
        websiteEs = {}
    } = data;

    const selectedContent = language === 'en' ? website : websiteEs;

    

    const onChangingValue = (field, value) => {
        if (field) {
            if (language === 'en') {
                onDataChange('website', { ...website, ...value })
            } else {
                onDataChange('websiteEs', { ...websiteEs, ...value })
            }
        }

    }



    return (
        <>
            <div className='panel-form-sec forms-main-wrapper facility-design facility-website f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block' }}>Select a Template</span>
                                <br />
                            </div>
                        </Grid>
                        <Grid onClick={() => onChangingValue('website', { template: 'template-1' } )} item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                            <div className={`template-preview-box ${selectedContent?.template === 'template-1' ? 'active' : ''} f-wrp`}>
                                <div className='selection-box f-wrp'>
                                    <span><img src={AppIcons.template1} alt='template 1' /></span>
                                </div>
                                <p>Template 1</p>
                            </div>
                        </Grid>
                        <Grid onClick={() => onChangingValue('website', { template: 'template-2' })} item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                            <div className={`template-preview-box ${selectedContent?.template === 'template-2' ? 'active' : ''} f-wrp`}>
                                <div className='selection-box f-wrp'>
                                    <span><img src={AppIcons.template2} alt='template 2' /></span>
                                </div> {/* <p>smart kids</p> */}
                                <p>Blocks & Colors</p>
                            </div>
                        </Grid>
                        <Grid onClick={() => onChangingValue('website', { template: 'template-3' })} item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                            <div className={`template-preview-box ${selectedContent?.template === 'template-3' ? 'active' : ''} f-wrp`}>
                                <div className='selection-box f-wrp'>
                                    <span><img src={AppIcons.template3} alt='template 1' /></span>
                                </div>
                                <p>Template 3</p>
                            </div>
                        </Grid>

                        {selectedContent?.template === 'template-1' && <TemplateFormOne language={language} data={{ website: selectedContent }} onDataChange={onChangingValue} />}
                        {selectedContent?.template === 'template-2' && <TemplateFormTwo language={language} data={{ website: selectedContent }} onDataChange={onChangingValue} />}

                        {/* <Grid item xs={12} sm={12} md={12}>
                            <iframe src="https://ppplatform.mihapp.co/template1" height="700" width="100%" title="Iframe Example" />
                        </Grid> */}
                    </Grid>
                </div>
            </div>
        </>
    );
}
FacilitiesCreateWebsite.propTypes = {
    onDataChange: propTypes.func,
    language: propTypes.string,
    data: propTypes.any
};
export default FacilitiesCreateWebsite;
