import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/notification/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../assets/images/icons/svgIcons';





const AccountProfileList = (props) => {
    const { 
        onViewProfile = () => null,
        // onAddNewProfile = () => null,
     } = props;
    const apiCaller = new ApiCaller('ProfileList');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([
        {
            account: 'Paula Ranser',
            email: "paular@email.com",
            status: 'active',
            id:1
        }
    ]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    // useEffect(() => {
    //     // getDataArr()
    //     return () => {
    //         // setDataArr([])
    //     };
    //     // eslint-disable-next-line
    // }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected profile has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        const justAIfBlocker = 15;
        if (selectedId && justAIfBlocker < 1) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected account has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        } else {
            setOpenDeleteDialog(false);
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'account',
            headerName: 'Account',
            width: 160,
            flex: 2,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { id, status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'Edit',
                        onClick: () => onViewProfile(id)
                    },
                    {
                        label: status === "active" ? "Pause" : "Accept",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <div className='accountProfile-main-list f-wrp'>
            <div className='accountProfile-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete profile`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this profile?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete profile'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </div>
    )
}


AccountProfileList.propTypes = {
    onViewProfile: PropTypes.func
}
export default AccountProfileList;