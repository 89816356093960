import { atom } from "recoil";
import persistAtom from "../recoilPersister";

export const adminConfigState = atom({
  key: 'adminConfig', // unique ID (with respect to other atoms/selectors)
  effects_UNSTABLE: [persistAtom],
  default: {
    facilitySection: {
      selectedPageView: "facility-list",
      selectedFacilityId: "",
    },
    newsSection: {
      selectedPageView: "news-list",
      selectedNewsId: "",
    }
  },
});