import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Divider, TextareaAutosize } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { contactUs } from '../../../services/api/contactUs';
import { showSnackbar } from '../../../components/notification';
import TextInput from '../../../components/inputs/textInput/textInput';

import '../../../assets/css/form.scss';
// ----------------------------------------------------------------------

export default function ContactUsForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        query: ''
    })

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.name && formData.email && formData.query ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            contactUs(formData).then((data) => {
                if (data.success) {
                    navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsLoading(false));

        }

    };

    /**
     * The signup form elements
     */
    const formElements = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            required: true,
            placeholder: 'Please enter your name',
            className: '',
            getValue: setFormValue
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            required: true,
            placeholder: 'Please enter your email address',
            className: '',
            getValue: setFormValue
        },
        {
            label: 'Phone',
            name: 'phoneNumber',
            type: 'number',
            required: false,
            className: '',
            placeholder: 'Please enter your phone',
            getValue: setFormValue
        },
    ];

    return (
        <>
            <Stack className='login-form standard-form' spacing={3}>
                {formElements.map((element, key) => (
                    <TextInput
                        key={`register-input-${key}`}
                        {...element}
                        useDefaultValidation
                        className='custom-textfield'
                    />
                ))}
                <TextareaAutosize
                    onChange={(e) => setFormValue('query', e.target.value)}
                    maxRows={5}
                    minRows={5}
                    aria-label="Message"
                    placeholder="Message"
                    className="no-padding-btm"
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Divider />
            </Stack>

            <LoadingButton
                loading={isLoading}
                loadingPosition='start'
                startIcon={<></>} // To remove Mui warning
                fullWidth
                disabled={!isValidForm()}
                size="large"
                type="submit"
                variant="contained"
                className='login-btn'
                onClick={onSubmit}>
                Contact us
            </LoadingButton>
        </>
    );
}
