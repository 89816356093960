import React from 'react';
import { Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import './index.scss'

// Custom components
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import { DATE_EXTEND_FORMAT } from '../../../constants';


const ViewNews = (props) => {
    const {
        onClose = () => null,
    } = props;


    return (
        <>
            <div className='back-btn-wrp f-wrp'>
                <IconButton
                    onClick={onClose}
                    aria-label="Go Back">
                    <SVGIcons.BackIcon />
                    Back
                </IconButton>
            </div>
            <div className='view-news-wrp f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className='preview-img-sec f-wrp'>
                            <img src={AppIcons.news} alt='News' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className='news-con-sec f-wrp'>
                            <div className='basic-details f-wrp'>
                                <p>By Poster Poster</p>
                                <p>{dayjs().format(DATE_EXTEND_FORMAT)}</p>
                            </div>
                            <div className='f-wrp'>
                                <h1>Lorem ipsum dolor sit amet?</h1>
                                <h2>Consectetur adipiscing elit, sed do eiusmod tempor</h2>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br /> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velitr?</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
ViewNews.propTypes = {
    onClose: PropTypes.func
}
export default ViewNews;












