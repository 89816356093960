import * as React from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';

import { getUniqueId } from '../../../helpers/helper.functions';

import './index.scss';

import { adminConfigState } from '../../../state/reducerAtoms/admin.atom';
import FacilityList from './list';
import AddEditFacility from './addEditFacility';
import FacilitiesView from './facilitiesView';


export default function FacilitiesSection() {
    const [configState, setConfigState] = useRecoilState(adminConfigState);

    /**
     * Gets the content to display based on the current selection
     */
    const getSelectedContent = () => {
        const selectedItem = configState?.facilitySection?.selectedPageView || 'facility-list';
        let contentSelected = <></>
        switch (selectedItem) {
            case 'facility-list':
                contentSelected = <FacilityList
                    key={`${getUniqueId()}-facility-key`}
                    onClose={() => {
                        modifyConfigState({ selectedFacilityId: "", selectedPageView: "facility-list" });
                    }}
                    onViewFacility={(id) => {
                        modifyConfigState({ selectedPageView: "facility-view", selectedFacilityId: id });
                    }}
                    onAddEditFacility={(id) => {
                        modifyConfigState({ selectedPageView: "create-facility", selectedFacilityId: id || '' });
                    }}
                />;
                break;
            case 'facility-view':
                contentSelected = configState?.facilitySection?.selectedFacilityId ? <FacilitiesView
                    key={`${getUniqueId(configState?.facilitySection?.selectedFacilityId)}-facility-key-${configState?.facilitySection?.selectedFacilityId}`}
                    id={configState?.facilitySection?.selectedFacilityId}
                    onClose={() => {
                        modifyConfigState({ selectedFacilityId: "", selectedPageView: "facility-list" });
                    }}
                /> : <span>not found</span>;
                break;
            case 'create-facility':
                contentSelected = <AddEditFacility
                    key={`${getUniqueId()}-facility-key`}
                    onClose={() => {
                        modifyConfigState({ selectedFacilityId: "", selectedPageView: "facility-list" });
                    }}
                />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilitySection: { ...prv.facilitySection, ...modifiedObj } } }));

    return (
        <>
            <Box component="div" className='facility-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                {getSelectedContent()}
            </Box>
        </>
    );
}