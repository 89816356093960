import SVGIcons from "../../assets/images/icons/svgIcons";

export default {
    // 'news': <SVGIcons.NewsIcon />,
    // 'home': <SVGIcons.HomeIcon />,
    // 'FoodIcon': <SVGIcons.FoodIcon />,
    // 'AgesIcon': <SVGIcons.AgesIcon />,
    // 'PlaygroundIcon': <SVGIcons.PlaygroundIcon />,
    // 'SubCareIcon': <SVGIcons.SubCareIcon />,
    // 'BilingualIcon': <SVGIcons.BilingualIcon />,
    // 'ProgramIcon1': <SVGIcons.ProgramIcon1 />,
    // 'ProgramIcon2': <SVGIcons.ProgramIcon2 />,
    // 'ProgramIcon3': <SVGIcons.ProgramIcon3 />,
    // 'ProgramIcon4': <SVGIcons.ProgramIcon4 />,
    // 'ProgramIcon5': <SVGIcons.ProgramIcon5 />,
    // 'ProgramIcon6': <SVGIcons.ProgramIcon6 />,
    // 'ProgramIcon7': <SVGIcons.ProgramIcon7 />,
    // 'ProgramIcon8': <SVGIcons.ProgramIcon8 />,
    // 'ProgramIcon9': <SVGIcons.ProgramIcon9 />,
    // 'ProgramIcon10': <SVGIcons.ProgramIcon10 />,
    // 'ProgramIcon11': <SVGIcons.ProgramIcon11 />,
    // 'ProgramIcon12': <SVGIcons.ProgramIcon12 />,
    // 'FriendlyPlayground': <SVGIcons.FriendlyPlayground />,
    // 'FriendlyMedical': <SVGIcons.FriendlyMedical />,
    // 'FriendlyCare': <SVGIcons.FriendlyCare />,
    // 'FriendlyHours': <SVGIcons.FriendlyHours />,
    // 'FriendlyAge': <SVGIcons.FriendlyAge />,
    // 'FriendlyDays': <SVGIcons.FriendlyDays />,
    // 'FriendlySubsidy': <SVGIcons.FriendlySubsidy />,
    // 'FriendlyProgramIcon1': <SVGIcons.FriendlyProgramIcon1 />,
    // 'FriendlyProgramIcon2': <SVGIcons.FriendlyProgramIcon2 />,
    // 'FriendlyProgramIcon3': <SVGIcons.FriendlyProgramIcon3 />,
    // 'FriendlyProgramIcon4': <SVGIcons.FriendlyProgramIcon4 />,
    // 'FriendlyProgramIcon5': <SVGIcons.FriendlyProgramIcon5 />,
    // 'FriendlyProgramIcon6': <SVGIcons.FriendlyProgramIcon6 />,
    // 'FriendlyProgramIcon7': <SVGIcons.FriendlyProgramIcon7 />,
    // 'FriendlyProgramIcon8': <SVGIcons.FriendlyProgramIcon8 />,
    // 'FriendlyProgramIcon9': <SVGIcons.FriendlyProgramIcon9 />,
    // 'FriendlyProgramIcon10': <SVGIcons.FriendlyProgramIcon10 />,
    // 'FriendlyProgramIcon11': <SVGIcons.FriendlyProgramIcon11 />,
    // 'FriendlyProgramIcon12': <SVGIcons.FriendlyProgramIcon12 />,
    // 'Act1Icon': <SVGIcons.Act1Icon />,
    // 'Act2Icon': <SVGIcons.Act2Icon />,
    // 'Act3Icon': <SVGIcons.Act3Icon />,
    // 'Act4Icon': <SVGIcons.Act4Icon />,
    // 'Act5Icon': <SVGIcons.Act5Icon />,
    // 'Act6Icon': <SVGIcons.Act6Icon />,
    // 'Act7Icon': <SVGIcons.Act7Icon />,
    // 'Act8Icon': <SVGIcons.Act8Icon />,
    // 'Act9Icon': <SVGIcons.Act9Icon />,
    // 'Act10Icon': <SVGIcons.Act10Icon />,
    // 'Act11Icon': <SVGIcons.Act11Icon />,
    // 'Act12Icon': <SVGIcons.Act12Icon />,
    // 'Act13Icon': <SVGIcons.Act13Icon />,
    // 'Act14Icon': <SVGIcons.Act14Icon />,
    // 'TimerIcon': <SVGIcons.TimerIcon />,
    // 'KidFaceIcon': <SVGIcons.KidFaceIcon />,
    // 'SunIcon': <SVGIcons.SunIcon />,
    // 'DollarIcon': <SVGIcons.DollarIcon />,
    // 'Program1Icon': <SVGIcons.Program1Icon />,
    // 'ProgramIcon13': <SVGIcons.ProgramIcon13 />,
    // 'ProgramIcon14': <SVGIcons.ProgramIcon14 />,
    // 'ProgramIcon15': <SVGIcons.ProgramIcon15 />,

    'Service1': <SVGIcons.Service1 />,
    'Service2': <SVGIcons.Service2 />,
    'Service3': <SVGIcons.Service3 />,
    'Service4': <SVGIcons.Service4 />,
    'Service5': <SVGIcons.Service5 />,
    'Service6': <SVGIcons.Service6 />,
    'Service7': <SVGIcons.Service7 />,
    'Service8': <SVGIcons.Service8 />,
    'Service9': <SVGIcons.Service9 />,
    'Service10': <SVGIcons.Service10 />,
    'Service11': <SVGIcons.Service11 />,
    'Service12': <SVGIcons.Service12 />,
    'Service13': <SVGIcons.Service13 />,
    'Service14': <SVGIcons.Service14 />,
    'Service15': <SVGIcons.Service15 />,
    'Service16': <SVGIcons.Service16 />,
    'Service17': <SVGIcons.Service17 />,
    'Service18': <SVGIcons.Service18 />,
    'Work1': <SVGIcons.Work1 />,
    'Work2': <SVGIcons.Work2 />,
    'Work3': <SVGIcons.Work3 />,
    'Work4': <SVGIcons.Work4 />,
    'Program1': <SVGIcons.Program1 />,
    'Program2': <SVGIcons.Program2 />,
    'Program3': <SVGIcons.Program3 />,
    'Program4': <SVGIcons.Program4 />,
    'Program5': <SVGIcons.Program5 />,
    'Program6': <SVGIcons.Program6 />,
    'Program7': <SVGIcons.Program7 />,
    'Program8': <SVGIcons.Program8 />,
    'Program9': <SVGIcons.Program9 />,
    'Program10': <SVGIcons.Program10 />,
    'Program11': <SVGIcons.Program11 />,
    'Program12': <SVGIcons.Program12 />,
    'Program13': <SVGIcons.Program13 />,
    'Program14': <SVGIcons.Program14 />,
    'Program15': <SVGIcons.Program15 />,
    'Program16': <SVGIcons.Program16 />,
    'Program17': <SVGIcons.Program17 />,
    'Program18': <SVGIcons.Program18 />,
    'Program19': <SVGIcons.Program19 />,
    'Program20': <SVGIcons.Program20 />,
    'Program21': <SVGIcons.Program21 />,
    'Program22': <SVGIcons.Program22 />,
    'Program23': <SVGIcons.Program23 />,
    'Program24': <SVGIcons.Program24 />,
    'Program25': <SVGIcons.Program25 />,
    'Program26': <SVGIcons.Program26 />,
    'Program27': <SVGIcons.Program27 />,
    'Program28': <SVGIcons.Program28 />,
    'Program29': <SVGIcons.Program29 />,
    'Program30': <SVGIcons.Program30 />,
    'Program31': <SVGIcons.Program31 />,
    'Program32': <SVGIcons.Program32 />,
    'Program33': <SVGIcons.Program33 />,
    'Program34': <SVGIcons.Program34 />,
    'Program35': <SVGIcons.Program35 />,
    'Program36': <SVGIcons.Program36 />,
    'Program37': <SVGIcons.Program37 />,
    'Program38': <SVGIcons.Program38 />,
    'Program39': <SVGIcons.Program39 />,
    'Program40': <SVGIcons.Program40 />,
    'Program41': <SVGIcons.Program41 />,
    'Program42': <SVGIcons.Program42 />,
    'Program43': <SVGIcons.Program43 />,
    'Program44': <SVGIcons.Program44 />,
    'Program45': <SVGIcons.Program45 />,
    'Program46': <SVGIcons.Program46 />,
    'Program47': <SVGIcons.Program47 />,
    'Program48': <SVGIcons.Program48 />,
    'Program49': <SVGIcons.Program49 />,
    'Program50': <SVGIcons.Program50 />,
}
                    