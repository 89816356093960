import { atom } from "recoil";
import persistAtom from "../recoilPersister";
import { getAuthRoleName } from '../../helpers/auth.helper';
import { ADMIN_ROLE } from '../../constants';

const userType = getAuthRoleName();
export const myPortalState = atom({
    key: 'myPortal', // unique ID (with respect to other atoms/selectors)
    effects_UNSTABLE: [persistAtom],
    default: {
      selectedNavItem: userType === ADMIN_ROLE ? 'dashboard' :'children',
      pageHeader: userType === ADMIN_ROLE ? 'Dashboard' :'children',
      isDrawerOpen: true,
    },
  });