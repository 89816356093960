import React from 'react';
import PropTypes from 'prop-types';
// Components
import Switch from '@mui/material/Switch';
import AppTable from '../../../../components/table/index';


const FacilitiesCreateFeatures = (props) => {
    const { data = {}, onDataChange = () => { } } = props;
    const { features = {} } = data;

    const basicFeatures = [
        {
            label: '1.Parents',
            id: 'parents',
            isActive: features?.parents || false,

        },
        {
            label: '2.Kid check in',
            id: 'kidCheckIn',
            isActive: features?.kidCheckIn || false,

        },
        {
            label: '3.Daily comm',
            id: 'dailyCommunication',
            isActive: features?.dailyCommunication || false,

        },
        {
            label: '4.Forms',
            id: 'forms',
            isActive: features?.forms || false,

        },
        {
            label: '5.Accident',
            id: 'accident',
            isActive: features?.accident || false,

        },
        {
            label: '6.Payment',
            id: 'payment',
            isActive: features?.payment || false,

        },
        {
            label: '7.Policies',
            id: 'policies',
            isActive: features?.policies || false,

        },
        {
            label: '8.Account',
            id: 'account',
            isActive: features?.account || false,

        },
    ];
    const premiumFeatures = [
        {
            label: '9.Dashboard',
            id: 'dashboard',
            isActive: features?.dashboard || false,

        },
        {
            label: '10.Inbox',
            id: 'inbox',
            isActive: features?.inbox || false,

        },
        {
            label: '11.Pricing',
            id: 'pricing',
            isActive: features?.pricing || false,

        },
        {
            label: '12.Bulletin',
            id: 'bulletin',
            isActive: features?.bulletin || false,

        },
        {
            label: '13.Employee',
            id: 'employee',
            isActive: features?.employee || false,

        },
        {
            label: '14.Administrators',
            id: 'administrators',
            isActive: features?.administrators || false,

        },
        {
            label: '15.PP Design',
            id: 'ppDesign',
            isActive: features?.ppDesign || false,

        },
        {
            label: '16.Extra storage',
            id: 'extraStorage',
            isActive: features?.extraStorage || false,

        },
        {
            label: '17.Parent registration',
            id: 'parentRegistration',
            isActive: features?.parentRegistration || false,

        },
    ];

    /**
     * Columns array to show 
     */
    const basicFeaturesColumns = [
        {
            field: 'label',
            headerName: 'Full',
            width: 160,
            flex: 1,
        },
        {
            field: 'isActive',
            headerName: '',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { isActive, id } = params.row;
                return <Switch checked={isActive} onChange={(e) => onDataChange('features', { ...features, ...{ [id]: e.target.checked } })} />
            }
        },


    ]

    const premiumFeaturesColumn = [
        {
            field: 'label',
            headerName: 'Premium',
            width: 160,
            flex: 1,
        },
        {
            field: 'isActive',
            headerName: '',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { isActive, id } = params.row;
                return <Switch checked={isActive} onChange={(e) => onDataChange('features', { ...features, ...{ [id]: e.target.checked } })} />
            }
        },
    ]

    

    return (
        <div className='feature-main-list f-wrp'>
            
            <div className='feature-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <div className='f-wrp' style={{ display: 'flex' }}>
                            <AppTable
                                columns={basicFeaturesColumns}
                                data={basicFeatures}
                            />
                            <AppTable
                                columns={premiumFeaturesColumn}
                                data={premiumFeatures}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


FacilitiesCreateFeatures.propTypes = {
    data: PropTypes.any,
    onDataChange: PropTypes.func
}
export default FacilitiesCreateFeatures;