import React, { useState } from 'react';
import { IconButton, MenuItem, Popover } from '@mui/material';
import { PropTypes } from 'prop-types';
import Iconify from '../iconify/Iconify';
import { getUniqueId } from '../../helpers/helper.functions';

const AppPopOverMenu = (props) => {
    const { options = [] } = props;
    const [open, setOpen] = useState(null);
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };


    const handleCloseMenu = () => {
        setOpen(null);
    };

    const anchorId = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={anchorId} size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
            <Popover
                key={'popover-menu'}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {options.map((option) => <MenuItem key={`${getUniqueId()}-${option.label}`} onClick={() => {
                    if (typeof option.onClick === "function") {
                        option.onClick()
                    }
                    handleCloseMenu();
                }} sx={{ color: '#1D1D1B' }}>{option.label}</MenuItem>
                )}
            </Popover>
        </>
    )
}
AppPopOverMenu.propTypes = {
    options: PropTypes.array,
}

export default AppPopOverMenu;