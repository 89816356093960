import * as React from 'react';
import { Button } from '@mui/material';
import TabLayout from '../../../components/tabs';

import './index.scss';

import AdminPortalHeader from '../components/header';
import AccountProfileList from './accountProfileList';
import AdminAccountDesign from './accountDesign';

const AdminAccountTab = () => {
    
    const tabNames = ["Profiles","Design"];
    const tabContents = [
        <span key={'to_profiles'}><AccountProfileList /></span>,        
        <span key={'to_dailyCheckIn'}><AdminAccountDesign /></span>,
    ];

    return (
        <><AdminPortalHeader header='Account' />
            <div className='tab-sec-wrp pay-table accountTab-section f-wrp'>
            <Button onClick={console.log('clicked')} className='red-btn' style={{ maxWidth: '275px', float: 'right', marginBottom: '0' }}>+ Create admin profile</Button>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default AdminAccountTab;