/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import './index.scss'
import AppIcons from '../../../../assets/images/icons';
import { DATE_FORMAT } from '../../../../constants';


const FacilityTabDetails = ({ facility = {} }) => {
    const { facilityInfo = {} } = facility;

    const facilityContent = [
        {
            title: 'Parents',
            value: '8',
            status: 'Active',
        },
        {
            title: 'Employees',
            value: '8',
            status: 'Active',
        },
        {
            title: 'Branch office',
            value: '8',
            status: 'Active',
        },
        {
            title: 'Children',
            value: '8',
            status: 'Active',
        },
        {
            title: 'Admins',
            value: '8',
            status: 'Active',
        },


    ];

    const data = [
        { label: 'Storage used', value: 75 },
    ];


    const [copied, setCopied] = useState(false);

    const handleCopyEmail = () => {
        if (facility?.email) {
            navigator.clipboard.writeText(facility.email).then(() => {
                setCopied(true);
                // Reset the copied state after 2 seconds
                setTimeout(() => setCopied(false), 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    };

    return (
        <>
            <div className='panel-form-sec forms-main-wrapper facility-details-wrp f-wrp' style={{ borderRadius: '24px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='each-details-sec f-wrp'>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block' }}>Facility info</span>
                                <br />
                            </div>
                            <div className='facility-basic f-wrp'>
                                <div className='facility-img'>
                                    {facility && facility?.facilityLogo?.tempURL ? <>
                                        <span><img src={facility?.facilityLogo?.tempURL} alt='facility' /></span>
                                    </> : <span><img src={AppIcons.news} alt='facility' /></span>}


                                    <a target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_FRONEND_URL}/${facility?.facilityURL}`}><h4>{facilityInfo?.name}</h4></a>
                                </div>
                                <div className='facility-basic-details'>
                                    <ul>
                                        <li><b>Website:</b><a target='_blank' rel="noreferrer" href={`${facilityInfo?.website}`}>{facilityInfo?.website}</a></li>
                                        <li><b>FacilityURL:</b><a target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_FRONEND_URL}/${facility?.facilityURL}`}>{facility?.facilityURL}</a></li>
                                        <li><b>Email:</b><span><a href={`mailto:${facilityInfo?.email}`}>{facilityInfo?.email}</a> </span></li>
                                        <li><b>Location:</b><span>{facilityInfo?.location}</span></li>
                                        <li><b>Phone:</b><span><a href={`tel:${facilityInfo?.phone}`}>{facilityInfo?.phone}</a> </span></li>
                                        <li><b>Facebook:</b><a target='_blank' rel="noreferrer" href={`${facilityInfo?.facebook}`}>{facilityInfo?.facebook}</a></li>
                                        <li><b>Instagram:</b><a target='_blank' rel="noreferrer" href={`${facilityInfo?.instagram}`}>{facilityInfo?.instagram}</a></li>
                                        <li><b>Twitter:</b><a target='_blank' rel="noreferrer" href={`${facilityInfo?.twitter}`}>{facilityInfo?.twitter}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='each-details-sec f-wrp'>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block' }}>Provider info</span>
                                <br />
                            </div>
                            <div className='facility-basic f-wrp'>
                                <div className='facility-basic-details'>
                                    <ul>
                                        <li><b>Provider name:</b><span>{`${facility?.firstName} ${facility?.lastName}`}</span></li>
                                        <li onClick={handleCopyEmail} style={{ cursor: 'pointer', position: 'relative' }}>
                                            <b>Email:</b><span>{facility?.email}</span>
                                            {copied && <span style={{ color: 'green', position: 'absolute', right: '0', top: '0' }}>Email copied to clipboard!</span>}
                                        </li>

                                        <li><b>Phone:</b><span>{facility?.phoneNumber}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='detail-sec-group f-wrp'>

                            {facilityContent.map((element, key) => (
                                <div className='each-details-sec f-wrp' key={key}>
                                    <div className='info-head f-wrp'>
                                        <span style={{ color: '#0F0A01', display: 'block' }}>{element.title}</span>
                                    </div>
                                    <div className='facility-basic f-wrp'>
                                        <span><b>{element.value}</b>{element.status}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <div className='each-details-sec f-wrp'>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block' }}>Statistics</span>
                                <br />
                            </div>

                            <div className='facility-chart-sec f-wrp'>
                                <div className='facility-chart'>
                                    <PieChart
                                        series={[
                                            {
                                                startAngle: 90,
                                                endAngle: 360,
                                                paddingAngle: 5,
                                                innerRadius: 60,
                                                outerRadius: 80,
                                                data,
                                                cx: 80,
                                                cy: 80,
                                            },
                                        ]}
                                        margin={{ right: 5 }}
                                        width={175}
                                        height={175}
                                        legend={{ hidden: false }}
                                    />
                                </div>
                                <div className='facility-basic-details'>
                                    <ul>
                                        <li><b>Clocks:</b><span>18,720</span></li>
                                        <li><b>Comms:</b><span>37,440</span></li>
                                        <li><b>Forms:</b><span>180</span></li>
                                        <li><b>Active for:</b><span>1y, 1m</span></li>
                                        <li><b>Since:</b><span>{dayjs(facilityInfo?.createdAt).format(DATE_FORMAT)}</span></li>
                                        <li><b>Features:</b><span>8</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <div className='each-details-sec f-wrp'>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block' }}>Full </span>
                                <br />
                            </div>
                            <div className='facility-img'>
                                <span><img src={AppIcons.news} alt='facility' /></span>
                            </div>
                        </div>
                    </Grid>



                </Grid>
            </div>
        </>
    );
}


FacilityTabDetails.propTypes = {
    facility: PropTypes.object
}
export default FacilityTabDetails;
