import React from 'react';
import ConfirmDialog from '../dialogs/confirm.dialog';
import { logoutUser } from '../../helpers/auth.helper';

const LogoutButton = ()=> {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    return (
        <>
        {/* eslint-disable-next-line */}
        <span onClick={()=>setOpenDeleteDialog(true)}>Logout</span>
        {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                title="Confirm logout"
                className={'delete-popup'}
                description={<span>Are you sure you want to logout?</span>}
                okayButtonText={'Logout'}
                onConfirm={() => logoutUser('Logged out successfully!')}
            />}
        </>
    )
}

export default LogoutButton;