/**
* @author Geethananth M
 * Use this file to define app related constants
 */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_PASSWORD_RULE = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_EXTEND_FORMAT = "DD MMMM YYYY";
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const PARENT_ROLE = 'parent';
export const ADMIN_ROLE = 'admin';