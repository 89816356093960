import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// @mui
import { Button } from '@mui/material';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/notification/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import AdminPortalHeader from '../components/header';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { DATE_FORMAT } from '../../../constants';

import './index.scss';

const FacilityList = (props) => {
    const {
        onViewFacility = () => null,
        onAddEditFacility = () => null,
    } = props;
    const apiCaller = new ApiCaller('users/providers');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()

        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected facility has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected facility has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        } else {
            setOpenDeleteDialog(false);
        }

    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'facilityInfo',
            headerName: 'Facility',
            width: 160,
            flex: 1,
            valueFormatter: ({ value }) => `${value?.name}`,
        },
        {
            field: 'facilityURL',
            headerName: 'URL',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Provider Name',
            flex: 1.5,
            valueGetter: ({ row }) => `${row?.firstName} ${row?.lastName}`
        },

        {
            field: 'activeParents',
            headerName: "Parents",
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'activeChildren',
            headerName: 'Children',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            width: 90,
        },
        {
            field: 'totalFormSubmissions',
            headerName: "Forms",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            flex: 0.8,
        },
        {
            field: 'totalDailyLogSubmissions',
            headerName: "Daily logs",
            width: 120,
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        // {
        //     field: 'plan',
        //     headerName: 'Plan',
        //     flex: 1,
        // },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'lastLogin',
            headerName: "Last login",
            headerAlign: 'center',
            align: 'center',
            width: 160,
            flex: 1,
            valueFormatter: ({ value }) => value ? `${dayjs(value).format(DATE_FORMAT)}` : '-',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.9,
            headerAlign: 'center',
            align: 'center',
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            sortable: false,
            renderCell: (params) => {
                const { id, status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onViewFacility(id)
                    },
                    {
                        label: 'Edit',
                        onClick: () => onAddEditFacility(id)
                    },
                    {
                        label: status === "active" ? "Inactive" : "Active",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <div className='facilities-main-list f-wrp'>
            <AdminPortalHeader header='Facilities' />
            <Button onClick={() => onAddEditFacility()} className='red-btn' style={{ maxWidth: '275px', float: 'right' }}>+ Create facility</Button>
            <div className='facilities-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete facility`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this facility?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete facility'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </div>
    )
}


FacilityList.propTypes = {
    onViewFacility: PropTypes.func,
    onAddEditFacility: PropTypes.func
}
export default FacilityList;