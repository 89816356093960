import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Grid } from '@mui/material';
import './index.scss'
// Custom components
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import FileUploader from '../../../../components/awsS3ImageUploader/file.uploader';


const FacilityTabDesign = (props) => {
    const { facility = {}} = props;
    const { design = {}, facilityLogo = {} } = facility;
    const [image, setImage] = useState();

    useEffect(() => {
        if (facilityLogo?.fileKey) {
            setImage(facilityLogo)
        }
    }, [facilityLogo]);



    /**
     * Removes a file by its key
     */
    const removeFile = () => {
        setImage('');
    }
    /**
     * onDataChange a file by its key
     */
    const onDataChange = () => {
        setImage('');
    }

    return (
        <>
            
            <div className='panel-form-sec forms-main-wrapper facility-design f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-head f-wrp'>
                    <span style={{ color: '#0F0A01', display: 'block' }}>Upload a logo</span>
                    <br />
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='form-upload-sec f-wrp'>

                                <div className='img-upload-wrp f-wrp'>
                                    <FileUploader
                                        acceptOnly='image/*'
                                        uploadCaption={'Select image'}
                                        onUploadEnd={(file) => {
                                            setImage('image', file)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='logo-list img-upload-wrp f-wrp'>
                                {image && image?.tempURL ? <>
                                    <div className='info-head f-wrp'>
                                        <br />
                                        <span style={{ color: '#0F0A01', display: 'block' }}>Logo</span>
                                        <br />
                                    </div>
                                    <div className='each-img-blk' key={`image-${image.fileKey}`}>
                                        <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span><img src={image.tempURL} alt={image.fileKey} /></span>
                                        </div>
                                    </div>
                                </> : null}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#0F0A01', display: 'block' }}>Change colors</span>
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select background color, this will affect Provider Portal and Parent Portal appearance.</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'light' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box light ${design?.theme === 'light' ? 'selected' : ''}`}>
                                <span>Light</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'dark' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box dark ${design?.theme === 'dark' ? 'selected' : ''}`}>
                                <span>Dark</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for highlights and buttons, this will affect Provider Portal and Parent Portal appearance.</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'blue' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_1 ${design?.buttonTheme === 'blue' ? 'selected' : ''}`}>
                                <span>Blue</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'green' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_2 ${design?.buttonTheme === 'green' ? 'selected' : ''}`}>
                                <span>Green</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'orange' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_3 ${design?.buttonTheme === 'orange' ? 'selected' : ''}`}>
                                <span>Orange</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'yellow' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_4 ${design?.buttonTheme === 'yellow' ? 'selected' : ''}`}>
                                <span>Yellow</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
FacilityTabDesign.propTypes = {
    facility: propTypes.any
};
export default FacilityTabDesign;
