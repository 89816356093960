import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import './index.scss'
// import ProfilePicUploader from '../../../components/awsS3ImageUploader/profilePic.uploader';
// Custom components
import SVGIcons from '../../../assets/images/icons/svgIcons';
// import AppIcons from '../../../assets/images/icons';

import { uploadImage } from '../../../services/api/aws';



const AdminAccountDesign = (props) => {

    const {
        onUploadStart = () => null,
        onUploadEnd = () => null,
        onRemoveFile = () => null,
        defaultImage = {}
    } = props;
    const [image, setImage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (defaultImage.fileKey) {
            setImage(defaultImage)
        }
    }, [defaultImage]);

    /**
       * Handles the file upload
       * @param {any} e 
       */
    const handleUpload = async (e) => {
        setIsLoading(true);
        onUploadStart();
        await uploadImage(e.target.files[0], (result) => {
            if (result.success) {
                const uploadedImg = { fileKey: result.fileKey, tempURL: result.tempURL };
                setImage(uploadedImg);
                onUploadEnd(uploadedImg);
            }
            e.target.value = '';
            setIsLoading(false);
        })
    }

    /**
     * Removes a file by its key
     */
    const removeFile = () => {
        setImage('');
        onRemoveFile('');
    }

    return (
        <>
            <div className='panel-form-sec forms-main-wrapper account-design f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-head f-wrp'>
                    <span style={{ color: '#0F0A01', display: 'block' }}>Upload a logo</span>
                    <br />
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='form-upload-sec f-wrp'>
                                {/* <label htmlFor='form-upload' className='red-txt'>
                                    <input id='form-upload' required type='file' style={{ opacity: '0', display: 'none' }} />
                                    <SVGIcons.UploadIcon />
                                    <p style={{ fontWeight: '700' }}>Upload file</p>
                                </label> */}

                                <div className='img-upload-wrp f-wrp'>
                                    {<div className='upload-img form-upload-sec single-upload-btn'>
                                        {isLoading ? <span className='sm-loader'>{SVGIcons.LoaderIcon()}</span> : null}
                                        <label htmlFor="prop-img" className='red-txt'> {SVGIcons.UploadIcon()}
                                            <input
                                                accept="image/*"
                                                className="property-image"
                                                id="prop-img"
                                                disabled={isLoading}
                                                type="file"
                                                onChange={handleUpload}
                                            />
                                            <p style={{ fontWeight: '700' }}>Upload file</p>
                                        </label>
                                    </div>}
                                </div>
                            </div>

                            <div className='logo-list img-upload-wrp f-wrp'>
                                {image && image?.tempURL ? <>
                                    <div className='info-head f-wrp'>
                                        <br />
                                        <span style={{ color: '#0F0A01', display: 'block' }}>Current logo</span>
                                        <br />
                                    </div>
                                    <div className='each-img-blk' key={`image-${image.fileKey}`}>
                                        <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span><img src={image.tempURL} alt={image.fileKey} /></span>
                                        </div>
                                    </div>
                                </> : null}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#0F0A01', display: 'block' }}>Change Parent Portal colors</span>
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for background</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className='clr-box light'>
                                <span>Light</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className='clr-box dark'>
                                <span>Dark</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for highlights and buttons</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className='clr-box btnClr_1'>
                                <span>Blue</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className='clr-box btnClr_2'>
                                <span>Green</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className='clr-box btnClr_3'>
                                <span>Orange</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className='clr-box btnClr_4'>
                                <span>Yellow</span>
                            </div>
                        </Grid>

                    </Grid>
                    <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                        <div className='ryt-btn-sec'>
                            <LoadingButton
                                // loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                // disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                // onClick={onSubmit}
                                style={{ backgroundColor: '#FF3D00' }}
                            >
                                Save changes
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
AdminAccountDesign.propTypes = {
    onUploadStart: propTypes.func,
    onUploadEnd: propTypes.func,
    onRemoveFile: propTypes.func,
    defaultImage: propTypes.any
};
export default AdminAccountDesign;
