import * as React from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';

import { getUniqueId } from '../../../helpers/helper.functions';

import './index.scss';

import { adminConfigState } from '../../../state/reducerAtoms/admin.atom';
import NewsList from './list';
import ViewNews from './viewNews';
import CreateNews from './createNews';


export default function NewsSection() {
    const [configState, setConfigState] = useRecoilState(adminConfigState);

    /**
     * Gets the content to display based on the current selection
     */
    const getSelectedContent = () => {
        const selectedItem = configState?.newsSection?.selectedPageView || 'news-list';
        let contentSelected = <></>
        switch (selectedItem) {
            case 'news-list':
                contentSelected = <NewsList
                    key={`${getUniqueId()}-news-key`}
                    onClose={() => {
                        modifyConfigState({ selectedNewsId: "", selectedPageView: "news-list" });
                    }}
                    onViewNews={(id) => {
                        modifyConfigState({ selectedPageView: "news-view", selectedNewsId: id });
                    }}
                    onAddNewNews={() => {
                        modifyConfigState({ selectedPageView: "create-news", selectedNewsId: "" });
                    }}
                />;
                break;
            case 'news-view':
                contentSelected = configState?.newsSection?.selectedNewsId ? <ViewNews
                    key={`${getUniqueId(configState?.newsSection?.selectedNewsId)}-news-key-${configState?.newsSection?.selectedNewsId}`}
                    id={configState?.newsSection?.selectedNewsId}
                    onClose={() => {
                        modifyConfigState({ selectedNewsId: "", selectedPageView: "news-list" });
                    }}
                /> : <span>not found</span>;
                break;
            case 'create-news':
                contentSelected = <CreateNews
                    key={`${getUniqueId()}-news-key`}
                    onClose={() => {
                        modifyConfigState({ selectedNewsId: "", selectedPageView: "news-list" });
                    }}
                    onCreateClick={(childId) => {
                        modifyConfigState({ selectedPageView: "create-news", selectedNewsId: childId });
                    }}
                />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ newsSection: { ...prv.newsSection, ...modifiedObj } } }));

    return (
        <>
            <Box component="div" className='news-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                {getSelectedContent()}
            </Box>
        </>
    );
}