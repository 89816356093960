import React from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack } from '@mui/material';

// components
import Grid from '@mui/material/Grid';

// css
import '../../../../assets/css/form.scss';

// Components
import TextInput from '../../../../components/inputs/textInput/textInput';

// Helpers
import { translateThis } from '../../../../helpers/language.helper';


const FacilitiesCreateDetails = (props) => {
    const formKey = 'facility-details-form';
    const {data={}, onDataChange=()=>{}} = props;
    const {facilityInfo = {}} = data;

   

    return (
        <>
            <div>
                <>
                   
                    <Stack key={formKey} className='standard-form form-full-width panel-form f-wrp' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3 style={{ color: '#0F0A01' }}>Facility</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_name`}
                                    label={translateThis('facilities_create_form_question_name')}
                                    name='name'
                                    id='facilities_create_form_question_name'                                    
                                    translationKey='facilities_create_form_question_name'
                                    type='text'
                                    placeholder='Name'
                                    className='custom-textfield'
                                    value={facilityInfo?.name || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_location`}
                                    label={translateThis('facilities_create_form_question_location')}
                                    name='location'
                                    id='facilities_create_form_question_location'
                                    translationKey='facilities_create_form_question_location'
                                    type='text'
                                    placeholder='Location'
                                    className='custom-textfield'
                                    value={facilityInfo?.location || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_url`}
                                    label={translateThis('facilities_create_form_question_url')}
                                    name='facilityURL'
                                    id='facilities_create_form_question_url'
                                    translationKey='facilities_create_form_question_url'
                                    type='text'
                                    placeholder='URL'
                                    className='custom-textfield'
                                    value={data?.facilityURL || ''}
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_website`}
                                    label={translateThis('facilities_create_form_question_website')}
                                    name='website'
                                    id='facilities_create_form_question_website'
                                    translationKey='facilities_create_form_question_website'
                                    type='text'
                                    placeholder='Website'
                                    className='custom-textfield'
                                    value={facilityInfo?.website || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_email`}
                                    label={translateThis('facilities_create_form_question_email')}
                                    name='email'
                                    id='facilities_create_form_question_email'
                                    translationKey='facilities_create_form_question_email'
                                    type='email'
                                    placeholder='Email'
                                    className='custom-textfield'
                                    value={facilityInfo?.email || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_phone`}
                                    label={translateThis('facilities_create_form_question_phone')}
                                    name='phone'
                                    id='facilities_create_form_question_phone'
                                    translationKey='facilities_create_form_question_phone'
                                    type='number'
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    value={facilityInfo?.phone || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_facebook`}
                                    label={translateThis('facilities_create_form_question_facebook')}
                                    name='facebook'
                                    id='facilities_create_form_question_facebook'
                                    translationKey='facilities_create_form_question_facebook'
                                    type='text'
                                    placeholder='Facebook'
                                    className='custom-textfield'
                                    value={facilityInfo?.facebook || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_instagram`}
                                    label={translateThis('facilities_create_form_question_instagram')}
                                    name='instagram'
                                    id='facilities_create_form_question_instagram'
                                    translationKey='facilities_create_form_question_instagram'
                                    type='text'
                                    placeholder='Instagram'
                                    className='custom-textfield'
                                    value={facilityInfo?.instagram || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`facilities_create_form_Key_twitter`}
                                    label={translateThis('facilities_create_form_question_twitter')}
                                    name='twitter'
                                    id='facilities_create_form_question_twitter'
                                    translationKey='facilities_create_form_question_twitter'
                                    type='text'
                                    placeholder='Twitter'
                                    className='custom-textfield'
                                    value={facilityInfo?.twitter || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3 style={{ color: '#0F0A01' }}>Provider</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerName`}
                                    label={translateThis('facilities_create_form_question_providerName')}
                                    name='firstName'
                                    id='facilities_create_form_question_providerName'
                                    translationKey='facilities_create_form_question_providerName'
                                    type='text'
                                    placeholder='First name'
                                    className='custom-textfield'
                                    value={data?.firstName || ''}
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerLastName`}
                                    label={translateThis('facilities_create_form_question_providerLastName')}
                                    name='lastName'
                                    id='facilities_create_form_question_providerLastName'
                                    translationKey='facilities_create_form_question_providerLastName'
                                    type='text'
                                    placeholder='Last name'
                                    className='custom-textfield'
                                    value={data?.lastName || ''}
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerEmail`}
                                    label={translateThis('facilities_create_form_question_providerEmail')}
                                    name='email'
                                    id='facilities_create_form_question_providerEmail'
                                    translationKey='facilities_create_form_question_providerEmail'
                                    type='email'
                                    placeholder='Email'
                                    className='custom-textfield'
                                    value={data?.email || ''}
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerPassword`}
                                    label={translateThis('facilities_create_form_question_providerPassword')}
                                    name='password'
                                    id='facilities_create_form_question_providerPassword'
                                    translationKey='facilities_create_form_question_providerPassword'
                                    type='password'
                                    placeholder='Password'
                                    className='custom-textfield'
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerPhone`}
                                    label={translateThis('facilities_create_form_question_providerPhone')}
                                    name='phoneNumber'
                                    id='facilities_create_form_question_providerPhone'
                                    translationKey='facilities_create_form_question_providerPhone'
                                    type='text'
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    value={data?.phoneNumber || ''}
                                    getValue={(name, value) => onDataChange(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerStripePublicKey`}
                                    label={translateThis('Stripe Public key')}
                                    name='public'
                                    id='facilities_create_form_question_providerStripePublicKey'
                                    translationKey='facilities_create_form_question_providerStripePublicKey'
                                    type='text'
                                    placeholder='Please enter'
                                    className='custom-textfield'
                                    value={data?.stripeKeys?.public || ''}
                                    getValue={(name, value) => onDataChange('stripeKeys', { ...data?.stripeKeys, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerStripeSecretKey`}
                                    label={translateThis('Stripe Secret key')}
                                    name='secret'
                                    id='facilities_create_form_question_providerStripeSecretKey'
                                    translationKey='facilities_create_form_question_providerStripeSecretKey'
                                    type='text'
                                    placeholder='Please enter'
                                    className='custom-textfield'
                                    value={data?.stripeKeys?.secret || ''}
                                    getValue={(name, value) => onDataChange('stripeKeys', { ...data?.stripeKeys, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`facilities_create_form_Key_providerStripeWebhookKey`}
                                    label={translateThis('Stripe Webhook key')}
                                    name='webhookSecret'
                                    id='facilities_create_form_question_providerStripeWebhookKey'
                                    translationKey='facilities_create_form_question_providerStripeWebhookKey'
                                    type='text'
                                    placeholder='Please enter'
                                    className='custom-textfield'
                                    value={data?.stripeKeys?.webhookSecret || ''}
                                    getValue={(name, value) => onDataChange('stripeKeys', { ...data?.stripeKeys, ...{ [name]: value } })}
                                    useDefaultValidation
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3 style={{ color: '#0F0A01' }}>Notes</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>

                                <TextInput
                                    key={`facilities_create_form_Key_note`}
                                    label={translateThis('facilities_create_form_question_note')}
                                    name='notes'
                                    id='facilities_create_form_question_note'
                                    translationKey='facilities_create_form_question_note'
                                    placeholder='Note'
                                    className='custom-textfield'
                                    value={facilityInfo?.notes || ''}
                                    getValue={(name, value) => onDataChange('facilityInfo', { ...facilityInfo, ...{ [name]: value } })}
                                    useDefaultValidation
                                    additionalProps={{
                                        multiline: true,
                                        rows:4
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </>

            </div>
        </>
    );
}

FacilitiesCreateDetails.propTypes = {
    data: propTypes.any,
    onDataChange: propTypes.func,
}

export default FacilitiesCreateDetails;