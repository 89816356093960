import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
// Custom components
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import FileUploader from '../../../../../components/awsS3ImageUploader/file.uploader';
// import AppIcons from '../../../../../assets/images/icons';

// Components
import TextInput from '../../../../../components/inputs/textInput/textInput';

// Helpers
import { translateThis } from '../../../../../helpers/language.helper';
import IconPicker from '../../../../../components/iconPicker/icon-picker';
import { getUniqueId } from '../../../../../helpers/helper.functions';
import IconViewer from '../../../../../components/iconPicker/icon-viewer';
import S3ImageViewerWithFallback from '../../../../../components/awsS3ImageViewer/ImgViewerWithFallback';




const TemplateFormTwo = (props) => {

    const { data = {}, onDataChange = () => { } } = props;
    const {
        // selectedTemplateData = {},
        website = {} } = data;

    const [aboutSideImage, setAboutSideImage] = useState(website?.aboutSideImage || '');

    const [favIcon, setFavIcon] = useState(website?.favIcon || '');
    const [bannerImage, setBannerImage] = useState(website?.bannerImage || '');
    const [serviceIcon, setServiceIcon] = useState();
    const [programIcon, setProgramIcon] = useState();
    const [classItemImage, setClassItemImage] = useState('');
    const [paymentSideImage, setPaymentSideImage] = useState(website?.paymentSideImage || '');
    const [activitiesSideImage, setActivitiesSideImage] = useState(website?.activitiesSideImage || '');
    const [contactUsImage, setContactUsImage] = useState(website?.contactUsImage || '');
    const [teachersImage, setTeachersImage] = useState('');
    const [testimonialsAuthorImage, setTestimonialsAuthorImage] = useState('');
    const [galleryImages, setGalleryImages] = useState(website?.galleryImages || '');
    const [service, setService] = useState({
        id: getUniqueId(),
        title: '',
        shortDescription: '',
        icon: ''
    });
    const [program, setProgram] = useState({
        id: getUniqueId(),
        title: '',
        icon: ''
    });

    const [classItem, setClassItem] = useState({
        id: getUniqueId(),
        title: '',
        subTitle: '',
        icon: '',
        ageLimit: '',
        workingDays: '',
        workingHours: '',
        fees: '',
        feesPer: '',
    });


    const [teacher, setTeacher] = useState({
        id: getUniqueId(),
        title: '',
        image: '',
        designation: '',
        description: '',
    });

    const [testimonial, setTestimonial] = useState({
        id: getUniqueId(),
        title: '',
        image: '',
        description: '',
    });

    const onServiceAdd = () => {
        if (service.title && service.shortDescription && service.icon) {
            const services = website?.services || [];
            services.push(service);
            onDataChange('website', { ...website, services });
            setService((prv) => ({ ...prv, id: getUniqueId(), title: '', shortDescription: '', icon: '' }))
        }

    }
    const onServiceRemove = (index) => {
        const services = website?.services || []
        if (services[index]) {
            services.splice(index, 1);
            onDataChange('website', { ...website, ...{ [services]: services } });
        }
    }

    const onProgramAdd = () => {
        if (program.title && program.icon) {
            const programs = website?.programs || [];
            programs.push(program);
            onDataChange('website', { ...website, programs });
            setProgram((prv) => ({ ...prv, id: getUniqueId(), title: '', icon: '' }))
        }

    }
    const onProgramRemove = (index) => {
        const programs = website?.programs || []
        if (programs[index]) {
            programs.splice(index, 1);
            onDataChange('website', { ...website, ...{ [programs]: programs } });
        }

    }

    const onTeacherAdd = () => {
        if (teacher.title && teacher.image) {
            const teachers = website?.teachers || [];
            teachers.push(teacher);
            onDataChange('website', { ...website, teachers });
            setTeacher((prv) => ({ ...prv, id: getUniqueId(), title: '', image: '', designation: '', description: '', }))
            setTeachersImage('')
        }

    }
    const onTeacherRemove = (index) => {
        const teachers = website?.teachers || []
        if (teachers[index]) {
            teachers.splice(index, 1);
            onDataChange('website', { ...website, ...{ [teachers]: teachers } });
        }
    }

    const onTestimonialAdd = () => {
        if (testimonial.title) {
            const testimonials = website?.testimonials || [];
            testimonials.push(testimonial);
            onDataChange('website', { ...website, testimonials });
            setTestimonial((prv) => ({ ...prv, id: getUniqueId(), title: '', image: '', description: '', }))
            setTestimonialsAuthorImage('');
        }

    }
    const onTestimonialRemove = (index) => {
        const testimonials = website?.testimonials || []
        if (testimonials[index]) {
            testimonials.splice(index, 1);
            onDataChange('website', { ...website, ...{ [testimonials]: testimonials } });
        }
    }

    const onClassItemAdd = () => {
        if (classItem.title && classItem.icon) {
            const classItems = website?.classItems || [];
            classItems.push(classItem);
            onDataChange('website', { ...website, classItems });
            setClassItem((prv) => ({ ...prv, id: getUniqueId(), title: '', icon: '', subTitle: '', ageLimit: '', workingDays: '', workingHours: '', fees: '', feesPer: '', }))
            setClassItemImage('');
        }
    }
    const onClassRemove = (index) => {
        const classItems = website?.classItems || []
        if (classItems[index]) {
            classItems.splice(index, 1);
            onDataChange('website', { ...website, ...{ [classItems]: classItems } });
        }
    }



    /**
     * Removes a file by its key
     */
    const removeFile = () => {
        setFavIcon('');
        setBannerImage('');
        setServiceIcon('');
        setProgramIcon('');
        setGalleryImages('');
        setClassItemImage('');
    }


    return (
        <>

            <div className='facility-template-form-2 f-wrp' style={{ padding: '24px', paddingRight: '0' }}>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#0F0A01', display: 'block' }}>Select color variant</span>
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for highlights and buttons, this will affect Provider Portal and Parent Portal appearance.</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('website', { ...website, ...{ colorPattern: 'colorPattern1' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box templateClrTheme_1 ${website?.colorPattern === 'colorPattern1' ? 'selected' : ''}`}>
                                <span>Select color</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('website', { ...website, ...{ colorPattern: 'colorPattern2' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box templateClrTheme_2 ${website?.colorPattern === 'colorPattern2' ? 'selected' : ''}`}>
                                <span>Select color</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('website', { ...website, ...{ colorPattern: 'colorPattern3' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box templateClrTheme_3 ${website?.colorPattern === 'colorPattern3' ? 'selected' : ''}`}>
                                <span>Select color</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('website', { ...website, ...{ colorPattern: 'colorPattern4' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box templateClrTheme_4 ${website?.colorPattern === 'colorPattern4' ? 'selected' : ''}`}>
                                <span>Select color</span>
                            </div>
                        </Grid>

                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={6}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Favicon</span>
                            </div>
                            <div className='flex-box favIcon-upload f-wrp'>
                                <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='.ico,image/x-icon'
                                            uploadCaption={'Select favicon'}
                                            onUploadEnd={(file) => {
                                                setFavIcon(file)
                                                onDataChange('website', { ...website, ...{ favIcon: file?.fileKey } })
                                            }}
                                        />
                                    </div>
                                </div>
                                {favIcon ? <div className='logo-list img-upload-wrp f-wrp'>
                                    <div className='each-img-blk' key={`image-${favIcon}`}>
                                        <button type='button' onClick={() => {
                                            setFavIcon('')
                                            onDataChange('website', { ...website, ...{ favIcon: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span>
                                                <S3ImageViewerWithFallback key={`file-${favIcon}`} fileKey={favIcon} />
                                            </span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Website Title</span>
                            </div>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_websiteTitle`}
                                label={translateThis('facilities_websiteTemplate_form_websiteTitle')}
                                name='websiteTitle'
                                id='facilities_websiteTemplate_form_websiteTitle'
                                translationKey='facilities_websiteTemplate_form_websiteTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_websiteTitle')}
                                className='custom-textfield'
                                value={website?.websiteTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ websiteTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        {/* ----------------------------------------------------------------------------- */}
                        <Grid className='hero-blk' container spacing={3} style={{ paddingLeft: '24px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <br />
                                    <h3 style={{ color: '#0F0A01' }}>Hero block</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingBottom: '25px', gap: '20px' }}>
                                    <span style={{ color: '#0F0A01', display: 'block' }}>Banner</span>
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_bannerId`}
                                        // label={translateThis('facilities_websiteTemplate_form_bannerId')}
                                        name='bannerId'
                                        id='facilities_websiteTemplate_form_bannerId'
                                        translationKey='facilities_websiteTemplate_form_bannerId'
                                        type='text'
                                        placeholder={translateThis('blockId')}
                                        className='custom-textfield headIdField'
                                        value={website?.bannerId || ''}
                                        getValue={(_, value) => onDataChange('website', { ...website, ...{ bannerId: value } })}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                </div>
                                <div className='flex-box banner-upload f-wrp'>
                                    <div className='form-upload-sec f-wrp'>
                                        <div className='img-upload-wrp f-wrp'>
                                            <FileUploader
                                                acceptOnly='image/*'
                                                uploadCaption={'Select image'}
                                                onUploadEnd={(file) => {
                                                    setBannerImage(file)
                                                    onDataChange('website', { ...website, ...{ bannerImage: file?.fileKey } })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {bannerImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                        <div className='each-img-blk' key={`image-${bannerImage}`}>
                                            <button type='button' onClick={() => {
                                                setBannerImage('')
                                                onDataChange('website', { ...website, ...{ bannerImage: '' } })
                                            }}>{SVGIcons.DeleteIcon()}</button>
                                            <div className='profile-img-sec'>
                                                <span>
                                                    <S3ImageViewerWithFallback key={`file-${bannerImage}`} fileKey={bannerImage} />
                                                </span>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_bannerTitle`}
                                    label={translateThis('facilities_websiteTemplate_form_bannerTitle')}
                                    name='bannerTitle'
                                    id='facilities_websiteTemplate_form_bannerTitle'
                                    translationKey='facilities_websiteTemplate_form_bannerTitle'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_bannerTitle')}
                                    className='custom-textfield'
                                    value={website?.bannerTitle || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ bannerTitle: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_bannerShortDescription`}
                                    label={translateThis('facilities_websiteTemplate_form_bannerShortDescription')}
                                    name='bannerShortDescription'
                                    id='facilities_websiteTemplate_form_bannerShortDescription'
                                    translationKey='facilities_websiteTemplate_form_bannerShortDescription'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_bannerShortDescription')}
                                    className='custom-textfield'
                                    value={website?.bannerShortDescription || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ bannerShortDescription: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_bannerButtonName1`}
                                    label={translateThis('facilities_websiteTemplate_form_bannerButtonName1')}
                                    name='bannerButtonName1'
                                    id='facilities_websiteTemplate_form_bannerButtonName1'
                                    translationKey='facilities_websiteTemplate_form_bannerButtonName1'
                                    type='text'
                                    placeholder={`${translateThis('facilities_websiteTemplate_form_bannerButtonName1')}`}
                                    className='custom-textfield'
                                    value={website?.bannerButtonName1 || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ bannerButtonName1: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_bannerButton1URL`}
                                    label={translateThis('facilities_websiteTemplate_form_bannerButton1URL')}
                                    name='bannerButton1URL'
                                    id='facilities_websiteTemplate_form_bannerButton1URL'
                                    translationKey='facilities_websiteTemplate_form_bannerButton1URL'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_bannerButton1URL')}
                                    className='custom-textfield'
                                    value={website?.bannerButton1URL || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ bannerButton1URL: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                <p style={{ margin: '0', paddingTop: '5px', fontSize: '12px' }}>Please give the Button url as follows,
                                    for My-portal or any internal portal link use( '/' eg: /my-portal )
                                    for Any-internal links use( '#' eg: #BlockId )
                                    for Any-External links use( 'http' eg: https://www.evolvechildcare.com/ )
                                    if we leave the URL blank it will open the contact popup,
                                    if we remove both button name and URL, then the button will hide
                                </p>
                            </Grid>
                        </Grid>

                        {/* ----------------------------------------------------------------------------- */}


                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Service block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_serviceId`}
                                    // label={translateThis('facilities_websiteTemplate_form_serviceId')}
                                    name='serviceId'
                                    id='facilities_websiteTemplate_form_serviceId'
                                    translationKey='facilities_websiteTemplate_form_serviceId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.serviceId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ serviceId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ paddingBottom: '24px' }}>
                                <span style={{ color: '#0F0A01' }}>Add services</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='each-service-box flex-box f-wrp'>
                                <div className='service-icon'>
                                    {!serviceIcon && !serviceIcon?.tempURL ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => setService((prv) => ({ ...prv, icon }))} />
                                    </div> : null}
                                    {service && service?.icon ? <div className='form-upload-view f-wrp'>
                                        <IconViewer icon={service.icon} />
                                    </div> : null}

                                </div>
                                <div key={service.id} className='service-con'>
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_serviceAddSectionTitle`}
                                        label={translateThis('facilities_websiteTemplate_form_serviceAddSectionTitle')}
                                        name='serviceAddSectionTitle'
                                        id='facilities_websiteTemplate_form_serviceAddSectionTitle'
                                        translationKey='facilities_websiteTemplate_form_serviceAddSectionTitle'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_serviceAddSectionTitle')}
                                        className='custom-textfield'
                                        value={service.title}
                                        getValue={(_, value) => setService((prv) => ({ ...prv, title: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_serviceAddSectionShortDescription`}
                                        label={translateThis('facilities_websiteTemplate_form_serviceAddSectionShortDescription')}
                                        name='serviceAddSectionShortDescription'
                                        id='facilities_websiteTemplate_form_serviceAddSectionShortDescription'
                                        translationKey='facilities_websiteTemplate_form_serviceAddSectionShortDescription'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_serviceAddSectionShortDescription')}
                                        className='custom-textfield'
                                        value={service.shortDescription}
                                        getValue={(_, value) => setService((prv) => ({ ...prv, shortDescription: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                </div>
                                <div className='add-new-service'>
                                    <Button
                                        disabled={!service.title || !service.shortDescription || !service.icon}
                                        onClick={onServiceAdd}
                                        className='Add-new-item'
                                    >Add</Button>
                                </div>

                            </div>
                        </Grid>
                        {website?.services?.length > 0 ? <Grid item xs={12} sm={12} md={12}>
                            {website?.services.map((service, index) => (
                                <div key={index} className='each-service-box flex-box f-wrp'>
                                    <div className='service-icon'>
                                        <div className='form-upload-view f-wrp' style={{ position: 'relative' }}>
                                            <IconViewer icon={service.icon} />
                                        </div>

                                    </div>
                                    <div key={service.id} className='service-con'>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_serviceAddSectionTitle`}
                                            label={translateThis('facilities_websiteTemplate_form_serviceAddSectionTitle')}
                                            name='serviceAddSectionTitle'
                                            id='facilities_websiteTemplate_form_serviceAddSectionTitle'
                                            translationKey='facilities_websiteTemplate_form_serviceAddSectionTitle'
                                            type='text'
                                            readOnly
                                            placeholder={translateThis('facilities_websiteTemplate_form_serviceAddSectionTitle')}
                                            className='custom-textfield'
                                            value={service.title}
                                            getValue={(_, value) => setService((prv) => ({ ...prv, title: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_serviceAddSectionShortDescription`}
                                            label={translateThis('facilities_websiteTemplate_form_serviceAddSectionShortDescription')}
                                            name='serviceAddSectionShortDescription'
                                            id='facilities_websiteTemplate_form_serviceAddSectionShortDescription'
                                            translationKey='facilities_websiteTemplate_form_serviceAddSectionShortDescription'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_serviceAddSectionShortDescription')}
                                            className='custom-textfield'
                                            readOnly
                                            value={service.shortDescription}
                                            getValue={(_, value) => setService((prv) => ({ ...prv, shortDescription: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </div>
                                    <div className='add-new-service'>
                                        <button type='button' onClick={() => onServiceRemove(index)} >{SVGIcons.DeleteIcon()}</button>
                                    </div>

                                </div>
                            ))}
                        </Grid> : null}

                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>About block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_aboutId`}
                                    name='aboutId'
                                    id='facilities_websiteTemplate_form_aboutId'
                                    translationKey='facilities_websiteTemplate_form_aboutId'
                                    type='text'
                                    placeholder={translateThis('aboutId')}
                                    className='custom-textfield headIdField'
                                    value={website?.aboutId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ aboutId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_aboutBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_aboutBlockTitle')}
                                name='aboutBlockTitle'
                                id='facilities_websiteTemplate_form_aboutBlockTitle'
                                translationKey='facilities_websiteTemplate_form_aboutBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_aboutBlockTitle')}
                                className='custom-textfield'
                                value={website?.aboutBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ aboutBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_aboutBlockSignature`}
                                label={translateThis('facilities_websiteTemplate_form_aboutBlockSignature')}
                                name='aboutBlockSignature'
                                id='facilities_websiteTemplate_form_aboutBlockSignature'
                                translationKey='facilities_websiteTemplate_form_aboutBlockSignature'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_aboutBlockSignature')}
                                className='custom-textfield'
                                value={website?.aboutBlockSignature || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ aboutBlockSignature: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_aboutBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_aboutBlockShortDescription')}
                                name='aboutBlockShortDescription'
                                id='facilities_websiteTemplate_form_aboutBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_aboutBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_aboutBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.aboutBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ aboutBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_aboutBlockDescription`}
                                label={translateThis('facilities_websiteTemplate_form_aboutBlockDescription')}
                                name='notes'
                                id='facilities_websiteTemplate_form_aboutBlockDescription'
                                translationKey='facilities_websiteTemplate_form_aboutBlockDescription'
                                placeholder={translateThis('facilities_websiteTemplate_form_aboutBlockDescription')}
                                className='custom-textfield'
                                value={website?.aboutBlockDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ aboutBlockDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                                additionalProps={{
                                    multiline: true,
                                    rows: 4
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ display: 'block', marginBottom: '10px', fontSize: '14px' }}>About side-image</span>
                            </div>
                            <div className='flex-box banner-upload f-wrp'>
                                {!aboutSideImage ? <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadCaption={'Select image'}
                                            onUploadEnd={(file) => {
                                                setAboutSideImage(file?.fileKey)
                                                onDataChange('website', { ...website, ...{ aboutSideImage: file?.fileKey } })
                                            }}
                                        />
                                    </div>
                                </div> : null}

                                {aboutSideImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                    <div className='each-img-blk' key={`image-${aboutSideImage}`}>
                                        <button type='button' onClick={() => {
                                            setAboutSideImage('')
                                            onDataChange('website', { ...website, ...{ aboutSideImage: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span>
                                                <S3ImageViewerWithFallback key={`file-${aboutSideImage}`} fileKey={aboutSideImage} />
                                            </span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>

                        {/* ----------------------------------------------------------------------------- */}


                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Contact us block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_contactUsBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_contactUsBlockId')}
                                    name='contactUsBlockId'
                                    id='facilities_websiteTemplate_form_contactUsBlockId'
                                    translationKey='facilities_websiteTemplate_form_contactUsBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.contactUsBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockTitle')}
                                name='contactUsBlockTitle'
                                id='facilities_websiteTemplate_form_contactUsBlockTitle'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockTitle')}
                                className='custom-textfield'
                                value={website?.contactUsBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockSubTitle`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockSubTitle')}
                                name='contactUsBlockSubTitle'
                                id='facilities_websiteTemplate_form_contactUsBlockSubTitle'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockSubTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockSubTitle')}
                                className='custom-textfield'
                                value={website?.contactUsBlockSubTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockSubTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockShortDescription')}
                                name='contactUsBlockShortDescription'
                                id='facilities_websiteTemplate_form_contactUsBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.contactUsBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockLicenseNumber`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockLicenseNumber')}
                                name='contactUsBlockLicenseNumber'
                                id='facilities_websiteTemplate_form_contactUsBlockLicenseNumber'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockLicenseNumber'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockLicenseNumber')}
                                className='custom-textfield'
                                value={website?.contactUsBlockLicenseNumber || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockLicenseNumber: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockAddress`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockAddress')}
                                name='contactUsBlockAddress'
                                id='facilities_websiteTemplate_form_contactUsBlockAddress'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockAddress'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockAddress')}
                                className='custom-textfield'
                                value={website?.contactUsBlockAddress || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockAddress: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockEmail`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockEmail')}
                                name='contactUsBlockEmail'
                                id='facilities_websiteTemplate_form_contactUsBlockEmail'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockEmail'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockEmail')}
                                className='custom-textfield'
                                value={website?.contactUsBlockEmail || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockEmail: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactUsBlockPhone`}
                                label={translateThis('facilities_websiteTemplate_form_contactUsBlockPhone')}
                                name='contactUsBlockPhone'
                                id='facilities_websiteTemplate_form_contactUsBlockPhone'
                                translationKey='facilities_websiteTemplate_form_contactUsBlockPhone'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactUsBlockPhone')}
                                className='custom-textfield'
                                value={website?.contactUsBlockPhone || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactUsBlockPhone: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_contactBlockButtonTitle`}
                                label={translateThis('facilities_websiteTemplate_form_contactButtonTitle')}
                                name='contactButtonTitle'
                                id='facilities_websiteTemplate_form_contactButtonTitle'
                                translationKey='facilities_websiteTemplate_form_contactButtonTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_contactButtonTitle')}
                                className='custom-textfield'
                                value={website?.contactButtonTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ contactButtonTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Contact-us side-image</span>
                            </div>
                            <div className='flex-box banner-upload f-wrp'>
                                <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadCaption={'Select image'}
                                            onUploadEnd={(file) => {
                                                setContactUsImage(file)
                                                onDataChange('website', { ...website, ...{ contactUsImage: file?.fileKey } })
                                            }}
                                        />
                                    </div>
                                </div>

                                {contactUsImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                    <div className='each-img-blk' key={`image-${contactUsImage}`}>
                                        <button type='button' onClick={() => {
                                            setContactUsImage('')
                                            onDataChange('website', { ...website, ...{ contactUsImage: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span>
                                                <S3ImageViewerWithFallback key={`file-${contactUsImage}`} fileKey={contactUsImage} />
                                            </span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>


                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Work block (classes)</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workId`}
                                    name='workId'
                                    id='facilities_websiteTemplate_form_workId'
                                    translationKey='facilities_websiteTemplate_form_workId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.workId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_workBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_workBlockTitle')}
                                name='workBlockTitle'
                                id='facilities_websiteTemplate_form_workBlockTitle'
                                translationKey='facilities_websiteTemplate_form_workBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_workBlockTitle')}
                                className='custom-textfield'
                                value={website?.workBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ workBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_workBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_workBlockShortDescription')}
                                name='workBlockShortDescription'
                                id='facilities_websiteTemplate_form_workBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_workBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_workBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.workBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ workBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <div key={classItem.id} className='each-extra-block f-wrp'>
                                <div className='each-service-box flex-box f-wrp' style={{ paddingBottom: '0' }}>
                                    <div className='service-icon'>
                                        {!classItemImage ? <div className='form-upload-sec f-wrp'>
                                            <div className='img-upload-wrp f-wrp'>
                                                <FileUploader
                                                    acceptOnly='image/*'
                                                    uploadCaption={'Select image'}
                                                    onUploadEnd={(file) => {
                                                        setClassItemImage(file)
                                                        onDataChange(setClassItem((prv) => ({ ...prv, icon: file })))
                                                    }}
                                                />
                                            </div>
                                        </div> : null}
                                        {classItemImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                            <div className='each-img-blk' key={`image-${classItem?.icon?.fileKey}`}>
                                                <button type='button' onClick={() => {
                                                    setClassItemImage('')
                                                    onDataChange(setClassItem((prv) => ({ ...prv, icon: '' })))
                                                }}>{SVGIcons.DeleteIcon()}</button>


                                                <div className='profile-img-sec'>
                                                    <span><S3ImageViewerWithFallback key={`file-${classItem?.icon?.fileKey}`} fileKey={classItem?.icon?.fileKey} /></span>
                                                </div>
                                            </div>
                                        </div> : null}
                                    </div>
                                    <div className='service-con'>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsTitle`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsTitle')}
                                            name='classItemsTitle'
                                            id='facilities_websiteTemplate_form_classItemsTitle'
                                            translationKey='facilities_websiteTemplate_form_classItemsTitle'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsTitle')}
                                            className='custom-textfield'
                                            value={classItem.title || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, title: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsSubTitle`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsSubTitle')}
                                            name='classItemsSubTitle'
                                            id='facilities_websiteTemplate_form_classItemsSubTitle'
                                            translationKey='facilities_websiteTemplate_form_classItemsSubTitle'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsSubTitle')}
                                            className='custom-textfield'
                                            value={classItem.subTitle || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, subTitle: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </div>
                                    <div className='add-new-service'>
                                        <Button
                                            disabled={!classItem.title || !classItem.icon}
                                            onClick={onClassItemAdd}
                                            className='Add-new-item'
                                        >Add</Button>
                                    </div>
                                </div>
                                <Grid container spacing={2} style={{ paddingBottom: '0px' }}>
                                    <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsItemsAge`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsItemsAge')}
                                            name='classItemsItemsAge'
                                            id='facilities_websiteTemplate_form_classItemsItemsAge'
                                            translationKey='facilities_websiteTemplate_form_classItemsItemsAge'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsItemsAge')}
                                            className='custom-textfield'
                                            value={classItem.ageLimit || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, ageLimit: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsDays`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsDays')}
                                            name='classItemsDays'
                                            id='facilities_websiteTemplate_form_classItemsDays'
                                            translationKey='facilities_websiteTemplate_form_classItemsDays'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsDays')}
                                            className='custom-textfield'
                                            value={classItem.workingDays || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, workingDays: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsHours`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsHours')}
                                            name='classItemsHours'
                                            id='facilities_websiteTemplate_form_classItemsHours'
                                            translationKey='facilities_websiteTemplate_form_classItemsHours'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsHours')}
                                            className='custom-textfield'
                                            value={classItem.workingHours || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, workingHours: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '4px' }}>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsFees`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsFees')}
                                            name='classItemsFees'
                                            id='facilities_websiteTemplate_form_classItemsFees'
                                            translationKey='facilities_websiteTemplate_form_classItemsFees'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsFees')}
                                            className='custom-textfield'
                                            value={classItem.fees || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, fees: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '4px' }}>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_classItemsFeesPer`}
                                            label={translateThis('facilities_websiteTemplate_form_classItemsFeesPer')}
                                            name='classItemsFeesPer'
                                            id='facilities_websiteTemplate_form_classItemsFeesPer'
                                            translationKey='facilities_websiteTemplate_form_classItemsFeesPer'
                                            type='text'
                                            placeholder={translateThis('facilities_websiteTemplate_form_classItemsFeesPer')}
                                            className='custom-textfield'
                                            value={classItem.feesPer || ''}
                                            getValue={(_, value) => setClassItem((prv) => ({ ...prv, feesPer: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        {website?.classItems?.length > 0 ? <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            {website?.classItems.map((classItem, index) => (
                                <div key={index} className='each-extra-block f-wrp' style={{ borderTop: '1px solid #e4e4e4', paddingTop: '20px' }}>
                                    <div className='each-service-box flex-box f-wrp'>
                                        <div className='service-icon'>
                                            {classItem && classItem?.icon ? <div className='logo-list img-upload-wrp f-wrp'>
                                                <div className='each-img-blk' key={`image-${classItem?.icon?.fileKey}`}>
                                                    <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                                                    <div className='profile-img-sec'>
                                                        <span>
                                                            <S3ImageViewerWithFallback key={`file-${classItem?.icon?.fileKey}`} fileKey={classItem?.icon?.fileKey} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        </div>


                                        <div className='service-con'>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsTitle`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsTitle')}
                                                name='classItemsTitle'
                                                id='facilities_websiteTemplate_form_classItemsTitle'
                                                translationKey='facilities_websiteTemplate_form_classItemsTitle'
                                                type='text'
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsTitle')}
                                                readOnly
                                                className='custom-textfield'
                                                value={classItem.title || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, title: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsSubTitle`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsSubTitle')}
                                                name='classItemsSubTitle'
                                                id='facilities_websiteTemplate_form_classItemsSubTitle'
                                                translationKey='facilities_websiteTemplate_form_classItemsSubTitle'
                                                type='text'
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsSubTitle')}
                                                readOnly
                                                className='custom-textfield'
                                                value={classItem.subTitle || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, description: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </div>
                                        <div className='add-new-service'>
                                            <Button
                                                disabled={!classItem.title || !classItem.icon}
                                                onClick={() => onClassRemove(index)}
                                                className='Add-new-item'
                                            >{SVGIcons.DeleteIcon()}</Button>
                                        </div>
                                    </div>
                                    <Grid container spacing={2} style={{ paddingBottom: '24px' }}>
                                        <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsItemsAge`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsItemsAge')}
                                                name='classItemsItemsAge'
                                                id='facilities_websiteTemplate_form_classItemsItemsAge'
                                                translationKey='facilities_websiteTemplate_form_classItemsItemsAge'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsItemsAge')}
                                                className='custom-textfield'
                                                value={classItem.ageLimit || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, ageLimit: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsDays`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsDays')}
                                                name='classItemsDays'
                                                id='facilities_websiteTemplate_form_classItemsDays'
                                                translationKey='facilities_websiteTemplate_form_classItemsDays'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsDays')}
                                                className='custom-textfield'
                                                value={classItem.workingDays || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, workingDays: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '4px' }}>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsHours`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsHours')}
                                                name='classItemsHours'
                                                id='facilities_websiteTemplate_form_classItemsHours'
                                                translationKey='facilities_websiteTemplate_form_classItemsHours'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsHours')}
                                                className='custom-textfield'
                                                value={classItem.workingHours || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, workingHours: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '4px' }}>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsFees`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsFees')}
                                                name='classItemsFees'
                                                id='facilities_websiteTemplate_form_classItemsFees'
                                                translationKey='facilities_websiteTemplate_form_classItemsFees'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsFees')}
                                                className='custom-textfield'
                                                value={classItem.fees || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, fees: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '4px' }}>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_classItemsFeesPer`}
                                                label={translateThis('facilities_websiteTemplate_form_classItemsFeesPer')}
                                                name='classItemsFeesPer'
                                                id='facilities_websiteTemplate_form_classItemsFeesPer'
                                                translationKey='facilities_websiteTemplate_form_classItemsFeesPer'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_classItemsFeesPer')}
                                                className='custom-textfield'
                                                value={classItem.feesPer || ''}
                                                getValue={(_, value) => setClassItem((prv) => ({ ...prv, feesPer: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </Grid> : null}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', }}>Working time</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <div className='each-service-box flex-box f-wrp' style={{ paddingBottom: '10px' }}>
                                <div className='service-icon' style={{ width: '85px', height: '70px' }}>
                                    {!website.workHoursIcon ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => onDataChange('website', { ...website, ...{ workHoursIcon: icon } })} />
                                    </div> : null}
                                    {website.workHoursIcon ? <div className='form-upload-view f-wrp'>
                                        <button type='button' className='icon-delete-btn' onClick={() => {
                                            onDataChange('website', { ...website, ...{ workHoursIcon: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <IconViewer icon={website.workHoursIcon} />
                                    </div> : null}
                                </div>
                            </div>

                            <div className='f-wrp'>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workHoursTitle`}
                                    label={translateThis('facilities_websiteTemplate_form_workHoursTitle')}
                                    name='workHoursTitle'
                                    id='facilities_websiteTemplate_form_workHoursTitle'
                                    translationKey='facilities_websiteTemplate_form_workHoursTitle'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workHoursTitle')}
                                    className='custom-textfield'
                                    value={website?.workHoursTitle || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workHoursTitle: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />

                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workHours`}
                                    label={translateThis('facilities_websiteTemplate_form_workHours')}
                                    name='workHours'
                                    id='facilities_websiteTemplate_form_workHours'
                                    translationKey='facilities_websiteTemplate_form_workHours'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workHours')}
                                    className='custom-textfield'
                                    value={website?.workHours || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workHours: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='each-service-box flex-box f-wrp' style={{ paddingBottom: '10px' }}>
                                <div className='service-icon' style={{ width: '85px', height: '70px' }}>
                                    {!website.workAcceptedAgesIcon ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => onDataChange('website', { ...website, ...{ workAcceptedAgesIcon: icon } })} />
                                    </div> : null}
                                    {website.workAcceptedAgesIcon ? <div className='form-upload-view f-wrp'>
                                        <button type='button' className='icon-delete-btn' onClick={() => {
                                            onDataChange('website', { ...website, ...{ workAcceptedAgesIcon: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <IconViewer icon={website.workAcceptedAgesIcon} />
                                    </div> : null}
                                </div>
                            </div>
                            <div className='f-wrp'>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workAcceptedAgesTitle`}
                                    label={translateThis('facilities_websiteTemplate_form_workAcceptedAgesTitle')}
                                    name='workAcceptedAgesTitle'
                                    id='facilities_websiteTemplate_form_workAcceptedAgesTitle'
                                    translationKey='facilities_websiteTemplate_form_workAcceptedAgesTitle'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workAcceptedAgesTitle')}
                                    className='custom-textfield'
                                    value={website?.workAcceptedAgesTitle || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workAcceptedAgesTitle: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />

                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workAcceptedAges`}
                                    label={translateThis('facilities_websiteTemplate_form_workAcceptedAges')}
                                    name='workAcceptedAges'
                                    id='facilities_websiteTemplate_form_workAcceptedAges'
                                    translationKey='facilities_websiteTemplate_form_workAcceptedAges'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workAcceptedAges')}
                                    className='custom-textfield'
                                    value={website?.workAcceptedAges || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workAcceptedAges: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='each-service-box flex-box f-wrp' style={{ paddingBottom: '10px' }}>
                                <div className='service-icon' style={{ width: '85px', height: '70px' }}>
                                    {!website.workDaysIcon ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => onDataChange('website', { ...website, ...{ workDaysIcon: icon } })} />
                                    </div> : null}
                                    {website.workDaysIcon ? <div className='form-upload-view f-wrp'>
                                        <button type='button' className='icon-delete-btn' onClick={() => {
                                            onDataChange('website', { ...website, ...{ workDaysIcon: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <IconViewer icon={website.workDaysIcon} />
                                    </div> : null}
                                </div>
                            </div>
                            <div className='f-wrp'>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workDaysTitle`}
                                    label={translateThis('facilities_websiteTemplate_form_workDaysTitle')}
                                    name='workDaysTitle'
                                    id='facilities_websiteTemplate_form_workDaysTitle'
                                    translationKey='facilities_websiteTemplate_form_workDaysTitle'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workDaysTitle')}
                                    className='custom-textfield'
                                    value={website?.workDaysTitle || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workDaysTitle: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />

                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workDays`}
                                    label={translateThis('facilities_websiteTemplate_form_workDays')}
                                    name='workDays'
                                    id='facilities_websiteTemplate_form_workDays'
                                    translationKey='facilities_websiteTemplate_form_workDays'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workDays')}
                                    className='custom-textfield'
                                    value={website?.workDays || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workDays: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='each-service-box flex-box f-wrp' style={{ paddingBottom: '10px' }}>
                                <div className='service-icon' style={{ width: '85px', height: '70px' }}>
                                    {!website.workSubsidyProgramsIcon ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => onDataChange('website', { ...website, ...{ workSubsidyProgramsIcon: icon } })} />
                                    </div> : null}
                                    {website.workSubsidyProgramsIcon ? <div className='form-upload-view f-wrp'>
                                        <button type='button' className='icon-delete-btn' onClick={() => {
                                            onDataChange('website', { ...website, ...{ workSubsidyProgramsIcon: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <IconViewer icon={website.workSubsidyProgramsIcon} />
                                    </div> : null}
                                </div>
                            </div>
                            <div className='f-wrp'>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workSubsidyProgramsTitle`}
                                    label={translateThis('facilities_websiteTemplate_form_workSubsidyProgramsTitle')}
                                    name='workSubsidyProgramsTitle'
                                    id='facilities_websiteTemplate_form_workSubsidyProgramsTitle'
                                    translationKey='facilities_websiteTemplate_form_workSubsidyProgramsTitle'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workSubsidyProgramsTitle')}
                                    className='custom-textfield'
                                    value={website?.workSubsidyProgramsTitle || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workSubsidyProgramsTitle: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />

                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_workSubsidyPrograms`}
                                    label={translateThis('facilities_websiteTemplate_form_workSubsidyPrograms')}
                                    name='workSubsidyPrograms'
                                    id='facilities_websiteTemplate_form_workSubsidyPrograms'
                                    translationKey='facilities_websiteTemplate_form_workSubsidyPrograms'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_workSubsidyPrograms')}
                                    className='custom-textfield'
                                    value={website?.workSubsidyPrograms || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ workSubsidyPrograms: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>


                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Activities block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_programBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_programBlockId')}
                                    name='programBlockId'
                                    id='facilities_websiteTemplate_form_programBlockId'
                                    translationKey='facilities_websiteTemplate_form_programBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.programBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ programBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_programBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_programBlockTitle')}
                                name='programBlockTitle'
                                id='facilities_websiteTemplate_form_programBlockTitle'
                                translationKey='facilities_websiteTemplate_form_programBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_programBlockTitle')}
                                className='custom-textfield'
                                value={website?.programBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ programBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_programBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_programBlockShortDescription')}
                                name='programBlockShortDescription'
                                id='facilities_websiteTemplate_form_programBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_programBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_programBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.programBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ programBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Activities side-image</span>
                            </div>
                            <div className='flex-box banner-upload f-wrp'>
                                <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadCaption={'Select image'}
                                            onUploadEnd={(file) => {
                                                setActivitiesSideImage(file)
                                                onDataChange('website', { ...website, ...{ activitiesSideImage: file?.fileKey } })
                                            }}
                                        />
                                    </div>
                                </div>

                                {activitiesSideImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                    <div className='each-img-blk' key={`image-${activitiesSideImage}`}>
                                        <button type='button' onClick={() => {
                                            setActivitiesSideImage('')
                                            onDataChange('website', { ...website, ...{ activitiesSideImage: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span>
                                                <S3ImageViewerWithFallback key={`file-${activitiesSideImage}`} fileKey={activitiesSideImage} />
                                            </span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ paddingBottom: '24px' }}>
                                <span style={{ color: '#0F0A01' }}>Add activities</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='each-service-box flex-box f-wrp'>

                                <div className='service-icon'>
                                    {!programIcon ? <div className='form-upload-sec f-wrp'>
                                        <IconPicker onSelect={(icon) => setProgram((prv) => ({ ...prv, icon }))} />
                                    </div> : null}
                                    {program && program?.icon ? <div className='form-upload-view f-wrp'>
                                        <IconViewer icon={program.icon} />
                                    </div> : null}
                                </div>
                                <div key={program.id} className='service-con'>
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_eachProgramTitle`}
                                        label={translateThis('facilities_websiteTemplate_form_eachProgramTitle')}
                                        name='eachProgramTitle'
                                        id='facilities_websiteTemplate_form_eachProgramTitle'
                                        translationKey='facilities_websiteTemplate_form_eachProgramTitle'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_eachProgramTitle')}
                                        className='custom-textfield'
                                        value={program.title}
                                        getValue={(_, value) => setProgram((prv) => ({ ...prv, title: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                </div>

                                <div className='add-new-service'>
                                    <Button
                                        disabled={!program.title || !program.icon}
                                        onClick={onProgramAdd}
                                        className='Add-new-item'
                                    >Add</Button>
                                </div>
                            </div>
                        </Grid>

                        {website?.programs?.length > 0 ? <Grid item xs={12} sm={12} md={12}>
                            {website?.programs.map((program, index) => (
                                <div key={index} className='each-service-box flex-box f-wrp'>
                                    <div className='service-icon'>
                                        <div className='form-upload-view f-wrp' style={{ position: 'relative' }}>
                                            <IconViewer icon={program.icon} />
                                        </div>

                                    </div>
                                    <div key={program.id} className='service-con'>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_eachProgramTitle`}
                                            label={translateThis('facilities_websiteTemplate_form_eachProgramTitle')}
                                            name='eachProgramTitle'
                                            id='facilities_websiteTemplate_form_eachProgramTitle'
                                            translationKey='facilities_websiteTemplate_form_eachProgramTitle'
                                            type='text'
                                            readOnly
                                            placeholder={translateThis('facilities_websiteTemplate_form_eachProgramTitle')}
                                            className='custom-textfield'
                                            value={program.title}
                                            getValue={(_, value) => setProgram((prv) => ({ ...prv, title: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </div>
                                    <div className='add-new-service'>
                                        <button type='button' onClick={() => onProgramRemove(index)} >{SVGIcons.DeleteIcon()}</button>
                                    </div>

                                </div>
                            ))}
                        </Grid> : null}

                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Gallery block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_galleryBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_galleryBlockId')}
                                    name='galleryBlockId'
                                    id='facilities_websiteTemplate_form_galleryBlockId'
                                    translationKey='facilities_websiteTemplate_form_galleryBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.galleryBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ galleryBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_galleryBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_galleryBlockTitle')}
                                name='galleryBlockTitle'
                                id='facilities_websiteTemplate_form_galleryBlockTitle'
                                translationKey='facilities_websiteTemplate_form_galleryBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_galleryBlockTitle')}
                                className='custom-textfield'
                                value={website?.galleryBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ galleryBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_galleryBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_galleryBlockShortDescription')}
                                name='galleryBlockShortDescription'
                                id='facilities_websiteTemplate_form_galleryBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_galleryBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_galleryBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.galleryBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ galleryBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='gallery-upload f-wrp'>
                                <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadIcon={SVGIcons.UploadIcon()}
                                            uploadCaption={`${translateThis('Upload a file')}`}
                                            showPreview
                                            onUploadEnd={(file) => setGalleryImages((prev) => {
                                                const newImgArr = [...prev];
                                                newImgArr.push(file);
                                                onDataChange('website', { ...website, ...{ galleryImages: [...newImgArr] } })
                                                return [...newImgArr];
                                            })}
                                        />
                                    </div>
                                </div>
                                {galleryImages.length > 0 ? <div className='each-gallery-box f-wrp'>
                                    <div className='flex-box'>
                                        {galleryImages.map((img, i) => (
                                            <span key={`${i}-${img}`} className='image-blk each-img-blk'>
                                                <S3ImageViewerWithFallback key={`file-${img.fileKey}`} fileKey={img.fileKey} />
                                                <button type='button' onClick={() => setGalleryImages((prev) => {
                                                    const newImgArr = [...prev].filter((imgAs) => img.fileKey !== imgAs.fileKey);
                                                    return [...newImgArr];
                                                })}>{SVGIcons.DeleteIcon()}</button>
                                            </span>
                                        ))}
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_virtualTourBtnLabel`}
                                    label={translateThis('facilities_websiteTemplate_form_virtualTourBtnLabel')}
                                    name='virtualTourBtnLabel'
                                    id='facilities_websiteTemplate_form_virtualTourBtnLabel'
                                    translationKey='facilities_websiteTemplate_form_virtualTourBtnLabel'
                                    type='text'
                                    placeholder={translateThis('facilities_websiteTemplate_form_virtualTourBtnLabel')}
                                    className='custom-textfield'
                                    value={website?.virtualTourBtnLabel || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ virtualTourBtnLabel: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                        </Grid>


                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Our teachers block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_ourTeachersBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockId')}
                                    name='ourTeachersBlockId'
                                    id='facilities_websiteTemplate_form_ourTeachersBlockId'
                                    translationKey='facilities_websiteTemplate_form_ourTeachersBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.ourTeachersBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTeachersBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourTeachersBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockTitle')}
                                name='ourTeachersBlockTitle'
                                id='facilities_websiteTemplate_form_ourTeachersBlockTitle'
                                translationKey='facilities_websiteTemplate_form_ourTeachersBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockTitle')}
                                className='custom-textfield'
                                value={website?.ourTeachersBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTeachersBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourTeachersBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockShortDescription')}
                                name='ourTeachersBlockShortDescription'
                                id='facilities_websiteTemplate_form_ourTeachersBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_ourTeachersBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.ourTeachersBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTeachersBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ paddingBottom: '24px' }}>
                                <span style={{ color: '#0F0A01' }}>Add teachers</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div key={teacher.id} className='each-service-box flex-box f-wrp'>
                                <div className='service-icon'>
                                    {!teachersImage ? <div className='form-upload-sec f-wrp'>
                                        <div className='img-upload-wrp f-wrp'>
                                            <FileUploader
                                                acceptOnly='image/*'
                                                uploadCaption={'Select image'}
                                                onUploadEnd={(file) => {
                                                    setTeachersImage(file)
                                                    onDataChange(setTeacher((prv) => ({ ...prv, image: file })))
                                                }}
                                            />
                                        </div>
                                    </div> : null}
                                    {teacher && teacher?.image ? <div className='logo-list img-upload-wrp f-wrp'>
                                        <div className='each-img-blk' key={`image-${teachersImage?.fileKey}`}>
                                            <button type='button' onClick={() => {
                                                setTeachersImage('')
                                                onDataChange(setTeacher((prv) => ({ ...prv, image: '' })))
                                            }}>{SVGIcons.DeleteIcon()}</button>
                                            <div className='profile-img-sec'>
                                                <span><S3ImageViewerWithFallback key={`file-${teachersImage?.fileKey}`} fileKey={teachersImage?.fileKey} /></span>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>


                                <div className='service-con teacher-content'>
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_ourTeachersBlockName`}
                                        label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockName')}
                                        name='ourTeachersBlockName'
                                        id='facilities_websiteTemplate_form_ourTeachersBlockName'
                                        translationKey='facilities_websiteTemplate_form_ourTeachersBlockName'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockName')}
                                        className='custom-textfield'
                                        value={teacher.title || ''}
                                        getValue={(_, value) => setTeacher((prv) => ({ ...prv, title: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_ourTeachersBlockPosition`}
                                        label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockPosition')}
                                        name='ourTeachersBlockPosition'
                                        id='facilities_websiteTemplate_form_ourTeachersBlockPosition'
                                        translationKey='facilities_websiteTemplate_form_ourTeachersBlockPosition'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockPosition')}
                                        className='custom-textfield'
                                        value={teacher.designation || ''}
                                        getValue={(_, value) => setTeacher((prv) => ({ ...prv, designation: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_ourTeachersBlockDescription`}
                                        label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockDescription')}
                                        name='ourTeachersBlockDescription'
                                        id='facilities_websiteTemplate_form_ourTeachersBlockDescription'
                                        translationKey='facilities_websiteTemplate_form_ourTeachersBlockDescription'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockDescription')}
                                        className='custom-textfield'
                                        value={teacher.description || ''}
                                        getValue={(_, value) => setTeacher((prv) => ({ ...prv, description: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                </div>
                                <div className='add-new-service'>
                                    <Button
                                        disabled={!teacher.title || !teacher.image}
                                        onClick={onTeacherAdd}
                                        className='Add-new-item'
                                    >{SVGIcons.PlusIcon()}</Button>
                                </div>
                            </div>
                        </Grid>

                        {website?.teachers?.length > 0 ?
                            <Grid item xs={12} sm={12} md={12} >
                                {website?.teachers.map((teacher, index) => (
                                    <div key={index} className='each-service-box flex-box f-wrp'>

                                        <div className='service-icon'>

                                            {teacher && teacher?.image ? <div className='logo-list img-upload-wrp f-wrp'>
                                                <div className='each-img-blk' key={`image-${teacher?.image?.fileKey}`}>
                                                    <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                                                    <div className='profile-img-sec'>
                                                        <span>
                                                            <S3ImageViewerWithFallback key={`file-${teacher?.image?.fileKey}`} fileKey={teacher?.image?.fileKey} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        </div>

                                        <div className='service-con teacher-content'>
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_ourTeachersBlockName`}
                                                label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockName')}
                                                name='ourTeachersBlockName'
                                                id='facilities_websiteTemplate_form_ourTeachersBlockName'
                                                translationKey='facilities_websiteTemplate_form_ourTeachersBlockName'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockName')}
                                                className='custom-textfield'
                                                value={teacher.title || ''}
                                                getValue={(_, value) => setTeacher((prv) => ({ ...prv, title: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_ourTeachersBlockPosition`}
                                                label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockPosition')}
                                                name='ourTeachersBlockPosition'
                                                id='facilities_websiteTemplate_form_ourTeachersBlockPosition'
                                                translationKey='facilities_websiteTemplate_form_ourTeachersBlockPosition'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockPosition')}
                                                className='custom-textfield'
                                                value={teacher.designation || ''}
                                                getValue={(_, value) => setTeacher((prv) => ({ ...prv, designation: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                            <TextInput
                                                key={`facilities_websiteTemplate_form_Key_ourTeachersBlockDescription`}
                                                label={translateThis('facilities_websiteTemplate_form_ourTeachersBlockDescription')}
                                                name='ourTeachersBlockDescription'
                                                id='facilities_websiteTemplate_form_ourTeachersBlockDescription'
                                                translationKey='facilities_websiteTemplate_form_ourTeachersBlockDescription'
                                                type='text'
                                                readOnly
                                                placeholder={translateThis('facilities_websiteTemplate_form_ourTeachersBlockDescription')}
                                                className='custom-textfield'
                                                value={teacher.description || ''}
                                                getValue={(_, value) => setTeacher((prv) => ({ ...prv, description: value }))}
                                                useDefaultValidation
                                                isRequired={false}
                                            />
                                        </div>
                                        <div className='add-new-service'>
                                            <button type='button' onClick={() => onTeacherRemove(index)} >{SVGIcons.DeleteIcon()}</button>
                                        </div>
                                    </div>))}
                            </Grid> : null}

                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <h3 style={{ color: '#0F0A01', paddingBottom: '16px' }}>Our testimonial block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_ourTestimonialBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_ourTestimonialBlockId')}
                                    name='ourTestimonialBlockId'
                                    id='facilities_websiteTemplate_form_ourTestimonialBlockId'
                                    translationKey='facilities_websiteTemplate_form_ourTestimonialBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.ourTestimonialBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTestimonialBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourTestimonialBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_ourTestimonialBlockTitle')}
                                name='ourTestimonialBlockTitle'
                                id='facilities_websiteTemplate_form_ourTestimonialBlockTitle'
                                translationKey='facilities_websiteTemplate_form_ourTestimonialBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourTestimonialBlockTitle')}
                                className='custom-textfield'
                                value={website?.ourTestimonialBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTestimonialBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourTestimonialBlockShortDescription`}
                                label={translateThis('facilities_websiteTemplate_form_ourTestimonialBlockShortDescription')}
                                name='ourTestimonialBlockShortDescription'
                                id='facilities_websiteTemplate_form_ourTestimonialBlockShortDescription'
                                translationKey='facilities_websiteTemplate_form_ourTestimonialBlockShortDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourTestimonialBlockShortDescription')}
                                className='custom-textfield'
                                value={website?.ourTestimonialBlockShortDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourTestimonialBlockShortDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Add Testimonials</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='each-service-box flex-box f-wrp'>
                                <div className='service-icon'>
                                    {!testimonialsAuthorImage ? <div className='form-upload-sec f-wrp'>
                                        <div className='img-upload-wrp f-wrp'>
                                            <FileUploader
                                                acceptOnly='image/*'
                                                uploadCaption={'Select image'}
                                                onUploadEnd={(file) => {
                                                    setTestimonialsAuthorImage(file)
                                                    onDataChange(setTestimonial((prv) => ({ ...prv, image: file })))
                                                }}
                                            />
                                        </div>
                                    </div> : null}
                                    {testimonialsAuthorImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                        <div className='each-img-blk' key={`image-${testimonialsAuthorImage.fileKey}`}>
                                            <button type='button' onClick={() => {
                                                setTestimonialsAuthorImage('');
                                                onDataChange(setTestimonial((prv) => ({ ...prv, image: '' })))
                                            }}>{SVGIcons.DeleteIcon()}</button>
                                            <div className='profile-img-sec'>
                                                <span><S3ImageViewerWithFallback key={`file-${testimonialsAuthorImage?.fileKey}`} fileKey={testimonialsAuthorImage?.fileKey} /></span>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                                <div key={testimonial.id} className='service-con'>
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_testimonialsAuthorName`}
                                        label={translateThis('facilities_websiteTemplate_form_testimonialsAuthorName')}
                                        name='testimonialsAuthorName'
                                        id='facilities_websiteTemplate_form_testimonialsAuthorName'
                                        translationKey='facilities_websiteTemplate_form_testimonialsAuthorName'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_testimonialsAuthorName')}
                                        className='custom-textfield'
                                        value={testimonial.title || ''}
                                        getValue={(_, value) => setTestimonial((prv) => ({ ...prv, title: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                    <TextInput
                                        key={`facilities_websiteTemplate_form_Key_testimonialsAuthorContent`}
                                        label={translateThis('facilities_websiteTemplate_form_testimonialsAuthorContent')}
                                        name='testimonialsAuthorContent'
                                        id='facilities_websiteTemplate_form_testimonialsAuthorContent'
                                        translationKey='facilities_websiteTemplate_form_testimonialsAuthorContent'
                                        type='text'
                                        placeholder={translateThis('facilities_websiteTemplate_form_testimonialsAuthorContent')}
                                        className='custom-textfield'
                                        value={testimonial.description || ''}
                                        getValue={(_, value) => setTestimonial((prv) => ({ ...prv, description: value }))}
                                        useDefaultValidation
                                        isRequired={false}
                                    />
                                </div>
                                <div className='add-new-service'>
                                    <Button
                                        disabled={!testimonial.title}
                                        onClick={onTestimonialAdd}
                                        className='Add-new-item'
                                    >{SVGIcons.PlusIcon()}</Button>
                                </div>
                            </div>
                        </Grid>

                        {website?.testimonials?.length > 0 ? <Grid item xs={12} sm={12} md={12} >
                            {website?.testimonials.map((testimonial, index) => (
                                <div key={index} className='each-service-box flex-box f-wrp'>

                                    <div className='service-icon'>

                                        {testimonial && testimonial?.image ? <div className='logo-list img-upload-wrp f-wrp'>
                                            <div className='each-img-blk' key={`image-${testimonial?.image?.fileKey}`}>
                                                <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                                                <div className='profile-img-sec'>
                                                    <span>
                                                        <S3ImageViewerWithFallback key={`file-${testimonial?.image?.fileKey}`} fileKey={testimonial?.image?.fileKey} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : <div className='logo-list img-upload-wrp f-wrp'>
                                            <div className='each-img-blk' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                                                <div className='profile-img-sec'>
                                                    <span>no img</span>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>


                                    <div className='service-con'>
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_testimonialsAuthorName`}
                                            label={translateThis('facilities_websiteTemplate_form_testimonialsAuthorName')}
                                            name='testimonialsAuthorName'
                                            id='facilities_websiteTemplate_form_testimonialsAuthorName'
                                            translationKey='facilities_websiteTemplate_form_testimonialsAuthorName'
                                            type='text'
                                            readOnly
                                            placeholder={translateThis('facilities_websiteTemplate_form_testimonialsAuthorName')}
                                            className='custom-textfield'
                                            value={testimonial.title || ''}
                                            getValue={(_, value) => setTestimonial((prv) => ({ ...prv, title: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                        <TextInput
                                            key={`facilities_websiteTemplate_form_Key_testimonialsAuthorContent`}
                                            label={translateThis('facilities_websiteTemplate_form_testimonialsAuthorContent')}
                                            name='testimonialsAuthorContent'
                                            id='facilities_websiteTemplate_form_testimonialsAuthorContent'
                                            translationKey='facilities_websiteTemplate_form_testimonialsAuthorContent'
                                            type='text'
                                            readOnly
                                            placeholder={translateThis('facilities_websiteTemplate_form_testimonialsAuthorContent')}
                                            className='custom-textfield'
                                            value={testimonial.description || ''}
                                            getValue={(_, value) => setTestimonial((prv) => ({ ...prv, description: value }))}
                                            useDefaultValidation
                                            isRequired={false}
                                        />
                                    </div>
                                    <div className='add-new-service'>
                                        <Button
                                            onClick={() => onTestimonialRemove(index)}
                                            className='Add-new-item'
                                        >{SVGIcons.DeleteIcon()}</Button>
                                    </div>
                                </div>))}
                        </Grid> : null}

                        {/* ----------------------------------------------------------------------------- */}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp' style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', gap: '20px' }}>
                                <h3 style={{ color: '#0F0A01' }}>Our payment block</h3>
                                <TextInput
                                    key={`facilities_websiteTemplate_form_Key_ourPaymentBlockId`}
                                    // label={translateThis('facilities_websiteTemplate_form_ourPaymentBlockId')}
                                    name='ourPaymentBlockId'
                                    id='facilities_websiteTemplate_form_ourPaymentBlockId'
                                    translationKey='facilities_websiteTemplate_form_ourPaymentBlockId'
                                    type='text'
                                    placeholder={translateThis('blockId')}
                                    className='custom-textfield headIdField'
                                    value={website?.ourPaymentBlockId || ''}
                                    getValue={(_, value) => onDataChange('website', { ...website, ...{ ourPaymentBlockId: value } })}
                                    useDefaultValidation
                                    isRequired={false}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourPaymentBlockTitle`}
                                label={translateThis('facilities_websiteTemplate_form_ourPaymentBlockTitle')}
                                name='ourPaymentBlockTitle'
                                id='facilities_websiteTemplate_form_ourPaymentBlockTitle'
                                translationKey='facilities_websiteTemplate_form_ourPaymentBlockTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourPaymentBlockTitle')}
                                className='custom-textfield'
                                value={website?.ourPaymentBlockTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourPaymentBlockTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourPaymentBlockButtonTitle`}
                                label={translateThis('facilities_websiteTemplate_form_ourPaymentBlockButtonTitle')}
                                name='ourPaymentBlockButtonTitle'
                                id='facilities_websiteTemplate_form_ourPaymentBlockButtonTitle'
                                translationKey='facilities_websiteTemplate_form_ourPaymentBlockButtonTitle'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourPaymentBlockButtonTitle')}
                                className='custom-textfield'
                                value={website?.ourPaymentBlockButtonTitle || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourPaymentBlockButtonTitle: value } })}
                                useDefaultValidation
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`facilities_websiteTemplate_form_Key_ourPaymentBlockDescription`}
                                label={translateThis('facilities_websiteTemplate_form_ourPaymentBlockDescription')}
                                name='ourPaymentBlockDescription'
                                id='facilities_websiteTemplate_form_ourPaymentBlockDescription'
                                translationKey='facilities_websiteTemplate_form_ourPaymentBlockDescription'
                                type='text'
                                placeholder={translateThis('facilities_websiteTemplate_form_ourPaymentBlockDescription')}
                                className='custom-textfield'
                                value={website?.ourPaymentBlockDescription || ''}
                                getValue={(_, value) => onDataChange('website', { ...website, ...{ ourPaymentBlockDescription: value } })}
                                useDefaultValidation
                                isRequired={false}
                                additionalProps={{
                                    multiline: true,
                                    rows: 4
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', display: 'block', marginBottom: '15px' }}>Our payment side-image</span>
                            </div>
                            <div className='flex-box banner-upload f-wrp'>
                                <div className='form-upload-sec f-wrp'>
                                    <div className='img-upload-wrp f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadCaption={'Select image'}
                                            onUploadEnd={(file) => {
                                                setPaymentSideImage(file)
                                                onDataChange('website', { ...website, ...{ paymentSideImage: file?.fileKey } })
                                            }}
                                        />
                                    </div>
                                </div>

                                {paymentSideImage ? <div className='logo-list img-upload-wrp f-wrp'>
                                    <div className='each-img-blk' key={`image-${paymentSideImage}`}>
                                        <button type='button' onClick={() => {
                                            setPaymentSideImage('')
                                            onDataChange('website', { ...website, ...{ paymentSideImage: '' } })
                                        }}>{SVGIcons.DeleteIcon()}</button>
                                        <div className='profile-img-sec'>
                                            <span>
                                                <S3ImageViewerWithFallback key={`file-${paymentSideImage}`} fileKey={paymentSideImage} />
                                            </span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </>
    );
}
TemplateFormTwo.propTypes = {
    onDataChange: propTypes.func,
    data: propTypes.any
};
export default TemplateFormTwo;
