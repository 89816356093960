import { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { atom, useAtom } from 'jotai';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';



import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
// @mui
import { Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// components

import { showSnackbar } from '../../../components/notification';

// Helpers


// Services
import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
import FacilitiesCreateDetails from './facilitiesCreate/facilitiesCreateDetails';
import FacilitiesCreateFeatures from './facilitiesCreate/facilitiesCreateFeatures';
import FacilitiesCreateDesign from './facilitiesCreate/facilitiesCreateDesign';
import AdminPortalHeader from '../components/header';
// ........................................................................
import ApiCaller from '../../../services/api/general';
import { adminConfigState } from '../../../state/reducerAtoms/admin.atom';
import FacilitiesCreateWebsite from './facilitiesCreate/facilitiesCreateWebsite';

const initialFormData = atom({});
const AddEditFacility = (props) => {
    const configState = useRecoilValue(adminConfigState);
    const apiCaller = new ApiCaller('users/providers');
    const facilityId = configState?.facilitySection?.selectedFacilityId;
    const { onClose = () => null } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useAtom(initialFormData);

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (facilityId) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [facilityId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(facilityId)
            .then((data) => {
                setFormData(data)
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.firstName &&
            formData.email &&
            formData.lastName &&
            formData.facilityInfo &&
            formData.facilityLogo &&
            formData.features &&
            formData.phoneNumber) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            "firstName": formData?.firstName,
            "lastName": formData?.lastName,
            "email": formData?.email,
            "phoneNumber": formData?.phoneNumber,
            "facilityInfo": formData?.facilityInfo,
            "features": formData?.features,
            "design": formData?.design,
            "website": formData?.website,
            "websiteEs": formData?.websiteEs,
            "selectedTemplateData": formData?.selectedTemplateData,
            "status": 'active',
            "facilityLogo": typeof formData?.facilityLogo === "object" ? formData?.facilityLogo?.fileKey : formData?.facilityLogo,
            "facilityURL": formData?.facilityURL,
            "stripeKeys": formData?.stripeKeys,
        };
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            if (facilityId) {
                if (formData?.password) {
                    postData.password = formData?.password;
                }
                apiCaller.updateData(facilityId, postData).then((data) => {
                    if (data.success) {
                        showSnackbar().success(data.message)
                        onClose();
                    } else {
                        showSnackbar().failure(data.message);
                    }
                }).finally(() => setIsSubmitting(false));
            } else {
                postData.password = formData?.password;
                apiCaller.postData(postData).then((data) => {
                    if (data.success) {
                        showSnackbar().success(data.message)
                        onClose();
                    } else {
                        showSnackbar().failure(data.message);
                    }
                }).finally(() => setIsSubmitting(false));

            }
        }
    };


    const steps = ['Details', 'Features', 'Design', 'Website','Website Spanish']
    const [activeIndex, setActiveIndex] = useState(0);

    const swiperRef = useRef(null);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const handleStepChange = (newIndex) => {
        setActiveIndex(newIndex);
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(newIndex);
        }
    };


    return (
        <>
            <div className='back-btn-wrp f-wrp'>
                <IconButton
                    onClick={onClose}
                    aria-label="Go Back">
                    <SVGIcons.BackIcon />
                    Back
                </IconButton>
            </div>
            <AdminPortalHeader header={facilityId ? 'Edit facility' : 'Create facility'} />
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&
                <div className='create-facility f-wrp'>

                    <div className='stepper-sec-wrp' style={{ marginBottom: '20px' }}>
                        <Stepper className="stepper-wrp" activeStep={activeIndex} alternativeLabel >
                            {steps.map((label, index) => (
                                <Step className="each-steps" key={label}
                                    onClick={() => handleStepChange(index)}
                                    completed={index <= activeIndex} >
                                    <StepLabel className="step-label">
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>


                    <Swiper
                        ref={swiperRef}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Pagination]}
                        spaceBetween={10}
                        slidesPerView={2}
                        className="create_facility_slider"
                        navigation

                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                            },
                            1200: {
                                slidesPerView: 1,
                                spaceBetween: 50,
                            },
                        }}
                        onSlideChange={handleSlideChange}
                        activeIndex={activeIndex}
                    >
                        <SwiperSlide><FacilitiesCreateDetails
                            data={formData}
                            onDataChange={setFormValue}
                        /></SwiperSlide>

                        <SwiperSlide><FacilitiesCreateFeatures
                            data={formData}
                            onDataChange={setFormValue}
                        /></SwiperSlide>

                        <SwiperSlide><FacilitiesCreateDesign
                            data={formData}
                            onDataChange={setFormValue}
                        /></SwiperSlide>

                        <SwiperSlide><FacilitiesCreateWebsite
                            data={formData}
                            language="en"
                            onDataChange={setFormValue}
                        /></SwiperSlide>
                        
                        <SwiperSlide><FacilitiesCreateWebsite
                            data={formData}
                            language="es"
                            onDataChange={setFormValue}
                        /></SwiperSlide>

                    </Swiper>


                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                        <div className='lft-btn-sec'>
                            <Button onClick={onClose} className='cancel-btn'>Cancel</Button>
                        </div>
                        <div className='ryt-btn-sec' style={{ display: 'flex' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="button"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}>
                                {facilityId ? 'Update' : 'Submit'}
                            </LoadingButton>

                        </div>
                    </div>
                </div>}
        </>
    );
}

AddEditFacility.propTypes = {
    onClose: propTypes.func,
}

export default AddEditFacility;











