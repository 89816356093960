import * as React from 'react';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import TabLayout from '../../../../components/tabs';

import './index.scss';

import ProviderPortalHeader from '../../components/header';
import FacilityTabFeatures from './facilityTabFeatures';
import FacilityTabDesign from './facilityTabDesign';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import FacilityTabDetails from './facilityTabDetails';
import { adminConfigState } from '../../../../state/reducerAtoms/admin.atom';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/notification/index';


const FacilitiesViewTab = (props) => {
    const {
        onClose = () => null,
    } = props;
    const configState = useRecoilValue(adminConfigState);
    const apiCaller = new ApiCaller('users/providers');
    const facilityId = configState?.facilitySection?.selectedFacilityId;

    const [isLoading, setIsLoading] = React.useState(false);
    const [facility, setFacility] = React.useState({});

    /**
 the legendary use effect function
 This will run very first on render
  */
    React.useEffect(() => {
        if (facilityId) {
            getData()
        }
        return () => {
            setFacility({})
        };
        // eslint-disable-next-line
    }, [facilityId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(facilityId)
            .then((data) => {
                setFacility(data)
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const tabNames = ["Details","Features", "Design", "Notes"];
    const tabContents = [
        <span key={'to_details'}><FacilityTabDetails facility={facility} /></span>,        
        <span key={'to_features'}><FacilityTabFeatures facility={facility} /></span>,
        <span key={'to_design'}><FacilityTabDesign facility={facility} /></span>,        
        <span key={'to_notes'}>{facility?.facilityInfo?.notes || 'NA'}</span>,
    ];

    return (
        <>
        <div className='back-btn-wrp f-wrp'>
                <IconButton
                    onClick={onClose}
                    aria-label="Go Back">
                    <SVGIcons.BackIcon />
                    Back
                </IconButton>
            </div>
        
            {(isLoading) ? <span>Loading...</span> : (!isLoading && facility) ? <div className='tab-sec-wrp pay-table accountTab-section f-wrp'>
            <ProviderPortalHeader header={facility?.facilityInfo?.name} />
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div> : null}
        </>
    )
}
FacilitiesViewTab.propTypes = {
    onClose: PropTypes.func
}
export default FacilitiesViewTab;