import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// @mui
import { Button } from '@mui/material';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/notification/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import AdminPortalHeader from '../components/header';
import { DATE_FORMAT } from '../../../constants/index';
// import SVGIcons from '../../../assets/images/icons/svgIcons';





const NewsList = (props) => {
    const { 
        onViewNews = () => null,
        onAddNewNews = () => null,
     } = props;
    const apiCaller = new ApiCaller('newss');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([
        {
            title: 'Lorem ipsum dolor sit amet?',
            text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco",
            publishingDate: dayjs(),
            // status: 'active',
            id:1
        },
        {
            title: 'Lorem ipsum dolor sit amet?',
            text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco",
            publishingDate: dayjs(),
            // status: 'active',
            id:1
        }
    ]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    // useEffect(() => {
    //     // getDataArr()
    //     return () => {
    //         // setDataArr([])
    //     };
    //     // eslint-disable-next-line
    // }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected news has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        const justAIfBlocker = 15;
        if (selectedId && justAIfBlocker < 1) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected news has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        } else {
            setOpenDeleteDialog(false);
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 160,
            flex: 2,
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 3,
        },
        {
            field: 'publishingDate',
            headerName: 'Publishing date',
            flex: 1,
            width: 75,
            valueFormatter: ({value}) => dayjs(value).format(DATE_FORMAT),
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     flex: 1,
        //     width: 90,
        //     sortable: false,
        //     renderCell: (params) => {
        //         const { status } = params.row;
        //         return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
        //             (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
        //     }
        // },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            width: 90,
            sortable: false,
            renderCell: (params) => {
                const { id, status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onViewNews(id)
                    },
                    {
                        label: 'Edit',
                        onClick: () => onViewNews(id)
                    },
                    {
                        label: status === "active" ? "Block" : "Accept",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <div className='news-main-list f-wrp'>
            <AdminPortalHeader header='News' />
            <Button onClick={onAddNewNews} className='red-btn' style={{ maxWidth: '275px', float: 'right' }}>+ Create new announcement</Button>
            <div className='news-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete news`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this news?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete news'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </div>
    )
}


NewsList.propTypes = {
    onViewNews: PropTypes.func,
    onAddNewNews: PropTypes.func
}
export default NewsList;