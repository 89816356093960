import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// components

import { showSnackbar } from '../../../components/notification';
import RichTextEditor from '../../../components/rich-text-editor';

// Helpers
import { getAuthId } from '../../../helpers/auth.helper';
import { answerGetter } from '../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../helpers/language.helper';


// Services
import { submitForm } from '../../../services/api/dynamicForms';
import { getChildFormBy } from '../../../services/api/child';
import TextInput from '../../../components/inputs/textInput/textInput';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
// ........................................................................




const CreateNews = (props) => {
    const formName = 'create_news_form';
    const { childId, onClose = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([]);
    const [initialFormData, setInitialFormData] = useState({});

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (childId && parentId) {
            getData()
        }
        return () => {
            setInitialFormData({})
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData(data)
                if (data?.formData) {
                    setFormData(data?.formData)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.name && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            userId: parentId,
            childId,
            formData
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&
                <div className='create-news f-wrp'>
                    <Stack className='standard-form form-full-width panel-form' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span style={{ color: '#0F0A01', fontWeight: '700' }}>Create news</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='form-upload-sec f-wrp'>
                                    <label htmlFor='form-upload' className='red-txt'>
                                        <input id='form-upload' required type='file' style={{ opacity: '0', display: 'none' }} />
                                        <SVGIcons.UploadIcon />
                                        <p style={{ fontWeight: '700' }}>Upload file</p>
                                    </label>
                                </div>

                            </Grid>


                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`news_create_form_Key_title`}
                                    label={translateThis('news_create_form_question_title')}
                                    name='news_create_form_question_title'
                                    id='news_create_form_question_title'
                                    value={answerGetter('news_create_form_question_title', initialFormData?.formData)?.answer}
                                    translationKey='news_create_form_question_title'
                                    type='text'
                                    required
                                    placeholder='Title'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'news_create_form_question_title',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`news_create_form_Key_subtitle`}
                                    label={translateThis('news_create_form_question_subtitle')}
                                    name='news_create_form_question_subtitle'
                                    id='news_create_form_question_subtitle'
                                    value={answerGetter('news_create_form_question_subtitle', initialFormData?.formData)?.answer}
                                    translationKey='news_create_form_question_subtitle'
                                    type='text'
                                    required
                                    placeholder='Title'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'news_create_form_question_subtitle',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>





                            <Grid item xs={12} sm={12} md={12}>
                                {/* <RichTextEditor defaultValue={propertyItem.features} onChange={(value) => setValue('features', JSON.stringify(value))} /> */}
                                <RichTextEditor
                                    key={`news_create_form_Key_content`}
                                    label={translateThis('news_create_form_add_content')}
                                    name='news_create_form_add_content'
                                    id='news_create_form_add_content'
                                    value={answerGetter('news_create_form_add_content', initialFormData?.formData)?.answer}
                                    translationKey='news_create_form_add_content'
                                    type='text'
                                    placeholder='Type'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'news_create_form_add_content',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                        </Grid>
                    </Stack>

                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                        <div className='lft-btn-sec'>
                            <Button onClick={onClose} className='cancel-btn'>Cancel</Button>
                        </div>
                        <div className='ryt-btn-sec' style={{display: 'flex'}}>
                            <Button onClick={onClose} className='pln-btn'>Preview</Button>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}>
                                Submit
                            </LoadingButton>

                        </div>
                    </div>
                </div>}
        </>
    );
}

CreateNews.propTypes = {
    childId: propTypes.string,
    onClose: propTypes.func,
}

export default CreateNews;











