import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './tabs.scss';



const TabLayout = (props) => {

    
    const {tabNames = [], tabContents=[]} =  props;
    const [value, setValue] = React.useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='tab-sec-wrp f-wrp'>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList className='tab-header' onChange={handleChange} aria-label="tab">
                                {tabNames.map((name, index)=><Tab key={`tab-key-${index}`} label={name} value={`${index}`} />)}
                            </TabList>
                        </Box>
                        <Box className="tab-body f-wrp">
                        {tabContents.map((content, index)=><TabPanel key={`key-${index}`} value={`${index}`}>{content}</TabPanel>)}
                        </Box>                        
                    </TabContext>
                </Box>
            </div>

        </>
    )
}
TabLayout.propTypes = {
    tabNames: PropTypes.array,
    tabContents: PropTypes.array
}
export default TabLayout;