import * as React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Logout } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import './index.scss';
import { isLoggedIn } from '../../../helpers/auth.helper';
import LogoutButton from '../../buttons/logout.button';


export default function BurgerMenu() {
  const isClientLoggedIn = isLoggedIn();

  const mobNavItems = [
    {
      title: 'About us',
      icon: AppIcons.Kcolorful,
      isHidden: false,
      link: '/sample-form'
    },
    {
      title: 'Program',
      icon: AppIcons.calenderIcon,
      isHidden: !isClientLoggedIn,
      link: '/sample-form'
    },
    {
      title: 'Staff',
      icon: AppIcons.twotoneFaceIcon,
      isHidden: !isClientLoggedIn,
      link: '/sample-form'
    },
    {
      title: 'Contact us',
      icon: AppIcons.phoneIcon,
      isHidden: false,
      link: '/contact-us'
    },
    {
      title: 'Children',
      icon: AppIcons.fileCheckIcon,
      isHidden: !isClientLoggedIn,
      link: '/children'
    },
    {
      title: 'Forms',
      icon: AppIcons.fileCheckIcon,
      isHidden: !isClientLoggedIn,
      link: '/children/forms'
    },
    {
      title: 'Policies',
      icon: AppIcons.carbonRuleIcon,
      isHidden: !isClientLoggedIn,
      link: '/sample-form'
    },
    {
      title: 'Tuition',
      icon: AppIcons.savingsIcon,
      isHidden: !isClientLoggedIn,
      link: '/sample-form'
    },
    {
      title: 'Pay online',
      icon: AppIcons.creditCardIcon,
      isHidden: !isClientLoggedIn,
      link: '/sample-form'
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /**
   * Handles the submenu click
   * @param {Event} event 
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the submenu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='f-wrp'>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className='burger-icon'
        >

          {SVGIcons.BurgerMenuIcon()}

        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className='menu-main-wrp'
        >
          {!isClientLoggedIn &&
            <MenuItem className='each-menuItem'>
              <Link to={'auth/register'}>
                <Button onClick={handleClose}>
                  <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                  <span>Register</span>
                </Button>
              </Link>
            </MenuItem>}
          {!isClientLoggedIn && <MenuItem className='each-menuItem'>
            <Link to={'auth/login'}>
              <Button onClick={handleClose}>
                <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                <span>Login</span>
              </Button>
            </Link>
          </MenuItem>}
          {!isClientLoggedIn &&
            <Divider />}
          {mobNavItems.filter((item) => !item.isHidden).map((element) => (
            <MenuItem key={element.title} className='each-menuItem'>
              <Link to={`${element.link}`} style={{ width: '100%' }} >
                <Button sx={{ textAlign: 'center' }} onClick={handleClose}>
                  <span className='menu-icon'><img src={element.icon} alt={element.title} /></span>
                  <span>{element.title}</span>
                </Button>
              </Link>
            </MenuItem>
          ))}
          {isClientLoggedIn && <Divider />}
          {isClientLoggedIn && <MenuItem className='each-menuItem' >
            <Link>
              <Button>
                <span className='menu-icon'><Logout /></span>
                <LogoutButton />
              </Button>
            </Link>
          </MenuItem>}
        </Menu>
      </div>
    </>

  )

}