/**
 * Gets the question for array of questions
 * @param {string} questionId string
 * @param {Array} dataArr the data array
 * @returns Object
 */
export const answerGetter = (questionId = '', dataArr = []) => {
    let question = {
        answer: ''
    };
    if (dataArr && dataArr.length > 0) {
        question = dataArr.find((q) => q.questionId === questionId);
    }
    return question;
}