import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { AppBar, Box, CssBaseline } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';


const AdminPortalHeader = ({ header = '' }) => {
    const { pageHeader = '' } = useRecoilValue(myPortalState);
    return (
        <>
            <Box className='panel-main-header'>
                <CssBaseline />
                <AppBar className='app-portal-header' position="relative">
                    <Toolbar>
                        <Typography variant="h4" noWrap component="div">
                            {header || pageHeader}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
        </>

    )
}

AdminPortalHeader.propTypes = {
    header: PropTypes.string,
}

export default AdminPortalHeader;