import React from 'react';
// State management
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import '../index.scss';
import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';
import LogoutButton from '../../../components/buttons/logout.button';




const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AdminPortalDrawer = () => {
    const theme = useTheme();
    const [myPortalInitialState, setMyPortalState] = useRecoilState(myPortalState);

    /**
     * Invokes when drawer open
     * @returns Fun
     */
    const handleDrawerOpen = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: true } }));

    /**
     * Invokes when drawer close
     * @returns Fun
     */
    const handleDrawerClose = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: false } }));

    /**
     * Invokes when user click a nav item
     * Passes the selected nav to my portal
     * @param {Object} item selected nav item
     */
    const onSelectNavItem = (item) => setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: item.id, pageHeader: item.pageHeader } }))


    const myNavItems = [
            
        {
            title: 'Dashboard',
            pageHeader: 'Dashboard',
            id: 'dashboard',
            icon: SVGIcons.DashboardIcon(),
            link: '/my-portal'
        },
        {
            title: 'Facilities',
            pageHeader: 'Facilities',
            id: 'facilities',
            icon: SVGIcons.HomeIcon(),
            link: '/my-portal'
        },
        {
            title: 'News',
            pageHeader: 'News',
            id: 'news',
            icon: SVGIcons.NewsIcon(),
            link: '/my-portal'
        },
        {
            title: 'Account',
            pageHeader: 'Account',
            id: 'account',
            icon: SVGIcons.ProfileIcon(),
            link: '/my-portal'
        },

    ];

    const loginMenu = [
        {
            title: 'Logout',
            className: 'logout',
            icon: SVGIcons.LogoutIcon(),
            link: '/my-portal'
        },
    ];

    return (
        <>
            <Drawer variant="permanent" open={myPortalInitialState.isDrawerOpen} className='provider-drawer'>
                <div>
                    <DrawerHeader className='logo-sec-wrp'>
                        {myPortalInitialState.isDrawerOpen ? <>
                            <Link to={'/'} className='logo-img'><img src={AppIcons.logo} alt='logo' /> </Link>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton></> : <><IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                                }}
                                style={{ margin: '0', width: '100%' }}
                            >
                                <MenuIcon />
                            </IconButton></>}


                    </DrawerHeader>
                    <List>
                        {myNavItems.map((element, key) => (
                            <ListItem
                                className={myPortalInitialState.selectedNavItem === element.id ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onSelectNavItem(element)}
                                key={key}>
                                <ListItemButton className={`listButton ${element.id}`}  sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                    <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                        <span>{element.icon}</span>
                                    </ListItemIcon>
                                    <ListItemText primary={element.title} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div>
                    <Divider />
                    <List>
                        {loginMenu.map((element, key) => (
                            <ListItem disablePadding sx={{ display: 'block' }} key={key}>
                                <ListItemButton className={`${element.className} listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                    <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                        <span>{element.icon}</span>
                                    </ListItemIcon>
                                    <ListItemText primary={<LogoutButton/>} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </>

    );
}

export default AdminPortalDrawer;