import * as React from 'react';
import { useRecoilValue } from 'recoil';

// @mui
import Box from '@mui/material/Box';

// css
import './index.scss';

// Components
import AppLayout from '../../layouts/app/AppLayout';
import AdminPortalDrawer from './components/drawer';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import Profile from '../profile';
import NewsSection from './newsSection';
import AdminAccountTab from './accountSection';
import FacilitiesSection from './facilitiesSection';



export default function AdminPortal() {

    const pageName = "Admin portal";
    const [content, setContent] = React.useState(<></>);
    const { selectedNavItem = 'dashboard' } = useRecoilValue(myPortalState);
    React.useEffect(() => {
        if (selectedNavItem) {
            onSelectNav(selectedNavItem)
        }

    }, [selectedNavItem]);

    /**
     * Sets the content to display based on the current selection
     * @param {string} item 
     */
    const onSelectNav = (item) => {
        let contentSelected = <span>Parents</span>
        switch (item) {
            case 'dashboard':
                contentSelected = <span>dashboard</span>;
                break;
            case 'facilities':
                contentSelected = <FacilitiesSection />;
                break;
            case 'news':
                contentSelected = <NewsSection />;
                break;
            case 'account':
                contentSelected = <Profile />;
                break;
            case 'accountList':
                contentSelected = <AdminAccountTab />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        setContent(contentSelected);
    }

    return (
        <>
            <AppLayout
                pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName='admin-portal f-wrp'
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <AdminPortalDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            {content}
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    );
}