import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @mui
// import { Stack, Divider, Button } from '@mui/material';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// components
import { register } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/notification';
import TextInput from '../../../components/inputs/textInput/textInput';
import { isLoggedIn } from '../../../helpers/auth.helper';



// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = isLoggedIn();
    const { from } = location.state || { from: { pathname: "/" } };
    
    if (isAuth) {
        navigate(from, { replace: true });
    }
    // state variables for handling input fields values
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    })

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.email && formData.password && formData.firstName && formData.lastName && isTermsChecked) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            register(formData).then((data) => {
                if (data.success) {
                    navigate(from, { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsLoading(false));

        }

    };

    /**
     * The signup form elements
     */
    const formElements = [
        {
            label: 'First name',
            name: 'firstName',
            type: 'text',
            required: true,
            placeholder: 'Please enter your first name',
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: 'Last name',
            name: 'lastName',
            type: 'text',
            required: true,
            placeholder: 'Please enter your last name',
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            required: true,
            placeholder: 'Please enter your email address',
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: 'Password',
            name: 'password',
            type: 'password',
            required: true,
            parentClassName: 'no-padding-btm',
            className: 'custom-textfield',
            placeholder: 'Please enter your password',
            getValue: setFormValue
        },
    ];

    return (
        <>
            {/* <Stack className='google-auth' spacing={3}>
                <Button className='google-auth-btn'> {SVGIcons.GoogleIcon()} Sign up with Google </Button>
            </Stack>
            <Stack className='divider' direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <Divider>or</Divider>
            </Stack> */}
            <Stack className='login-form standard-form' spacing={3}>
                {formElements.map((element, key) => (
                    <TextInput
                        key={`register-input-${key}`}
                        {...element}
                        useDefaultValidation
                    />
                ))}

                <FormGroup className='custom-checkbox'>
                    <FormControlLabel control={<Checkbox checked={isTermsChecked} onChange={(e)=>setIsTermsChecked(e.target.checked)} />} label={
                        <>I agree to the <Link to={'/'}>Terms of use</Link> and <Link to={'/auth/login'}>Privacy policy</Link></>
                    } />
                </FormGroup>

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    Sign up
                </LoadingButton>
            </Stack>
            <Stack className='additional-btn' variant='div' spacing={3}>
                <p>Already have an account? <Link to={'/auth/login'}>Log in</Link></p>
            </Stack>
            
        </>
    );
}
