import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAuthRoleName } from '../../helpers/auth.helper';
import { ADMIN_ROLE } from '../../constants/index';
import AdminPortal from '../../features/adminPortal';



const MyPortal = () => {
    const loggedInAuthType = getAuthRoleName();

    return (
        <>
            {loggedInAuthType === ADMIN_ROLE ? <AdminPortal /> :
                    <Navigate to={'/auth/login'} />}
        </>
    )

}


export default MyPortal;