import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import CustomNoRowsOverlay from './noRowsOverlay';
import CustomLoadingOverlay from './loadingOverlay';
import ServerSidePagination from './serverSidePagination';
import ClientPagination from './clientSidePagination';


// Create the data grid options object with columns and rowData properties set to our own values
const AppTable = (props) => {
    const { isLoading = false, columns = [], data = [], useServerPagination = false, paginationFn = () => null, paginationData = {} } = props;


    /**
     * 
     * @returns JSX Element
     */
    function CustomToolbar() {
        return (
            <GridToolbarContainer className='app-grid-tool-bar'>
                <GridToolbarQuickFilter />
                <GridToolbarExport
                    printOptions={{
                        disableToolbarButton: true
                    }}
                />
            </GridToolbarContainer>
        );
    }



    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                density='comfortable'
                rows={data}
                autoHeight
                loading={isLoading}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                pagination
                slots={{
                    toolbar: CustomToolbar,
                    pagination: (props) => useServerPagination ? ServerSidePagination({ ...props, ...{ paginationFn, paginationData } })
                        : ClientPagination(props)
                    ,
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: CustomLoadingOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </Box>
    );
}

AppTable.propTypes = {
    isLoading: PropTypes.bool,
    useServerPagination: PropTypes.bool,
    data: PropTypes.array,
    paginationFn: PropTypes.func,
    paginationData: PropTypes.object,
    columns: PropTypes.array
}

export default AppTable;